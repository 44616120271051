/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

export function ModalFileUploader(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    acceptedFiles: ".glb,.gltf,.fbx,.obj,.zip",
  });

  useEffect(() => {
    const file = acceptedFiles;
    if (file) {
      props.onUpload(file);
    }
  }, [acceptedFiles]);

  return (
        <Content {...getRootProps({ className: "dropzone" })} style={{cursor: "pointer"}}>
          <input {...getInputProps()} />
          <img src="assets/images/dropzone.png" />
          <Label>
            Drag and drop a model from your computer, or click to select a
            file
          </Label>
        </Content>
  );
}

export default ModalFileUploader;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  padding: 44px;
  background: white;
  border-radius: 8px 8px 8px 8px;
  align-self: stretch;
`;

const Label = styled.h1`
  font-family: "SkyTextRegular";
  font-size: 14px;
  font-weight: "500";
  max-width: 250px;
  background-image: linear-gradient(90deg, #FF9421 0%, #F2029C 27.08%, #C400D9 51.04%, #8A02F5 76.56%, #235BFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

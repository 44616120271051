import { useEffect, useState } from "react";
import styled from "styled-components";
import NavTop from "../components/ui/organisms/NavTop";
import { Navigate } from "react-router-dom";
import { TemplateHierarchy } from "../components/ui/organisms/TemplateHierarchy";
import { TemplateViewer } from "../components/templateViewer";
import { UploadModal } from "../components/ui/organisms/UploadModal";
import { TemplateDetails } from "../components/ui/organisms/TemplateDetails";
import { generateTemplatePreviewJSON } from "../utils/exportUtils";
import { PlayerPreview } from "../components/ui/organisms/PlayerPreview";
import modelStore from "../lib/stores/store";
import { getTemplate } from "../utils/uploadUtils";

const SITE_ID =
  "97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1";

export const TemplatePage = ({signedIn}) => {
    const [selection, setSelection] = useState({});
    const [hierarchyWidth, setHierarchyWidth] = useState(240);
    const [dragging, setDragging] = useState(false);
    const [allObjects, setAllObjects] = useState([]);

    const [selectedDetails, setSelectedDetails] = useState(-1);
    const [uploadType, setUploadType] = useState(null);
    const [exportStatus, setExportStatus] = useState(false);
    const [environment, setEnvironment] = useState(null);

    const [previewJSON, setPreviewJSON] = useState(null);
    const [existingJSON, setExistingJSON] = useState(null);

    const [disableHotkeys, setDisableHotkeys] = useState(false);

    const {templateId, name} = modelStore();
    const [loadingTemplate, setLoadingTemplate] = useState(templateId ? true : false);

    useEffect(() => {
        if (templateId) {
            getTemplate(SITE_ID, templateId).then((res) => {
                setExistingJSON(res);
            });       
        } else {
            setExistingJSON(null);
        }
    }, []);

    useEffect(() => {
        setDisableHotkeys(uploadType !== null);
    }, [uploadType]);

    useEffect(() => {
        if (selection) {
           var obj = allObjects.find(element => selection[element.uuid]);
           if (obj) {
            setSelectedDetails(allObjects.indexOf(obj));
           } else {
            setSelectedDetails(-1);
           }
        } else {
            setSelectedDetails(-1);
        }
    }, [selection, setSelectedDetails, allObjects]);

    const onPreviewTemplate = () => {
        setPreviewJSON({});
        generateTemplatePreviewJSON(SITE_ID, allObjects, environment).then((res) => {
            setPreviewJSON(res);
        });
    }

    if (!signedIn) {
        return <Navigate to={"/login"} />;
    }
    return (
        <Container style={{cursor: dragging ? 'col-resize' : 'unset'}}>
        <NavTop productName={name ? name : "Scene"} />
        <Main
            onMouseMove={(e) => {
            if (dragging) {
                setHierarchyWidth(Math.max(240,e.clientX + 5));
            }
            }}
            onMouseUp={(e) => {
            setDragging(false);
            }}
        >
            {loadingTemplate && <Loading><p>Downloading template...</p></Loading>} 
            <TemplateHierarchy selected={selection} allObjects={allObjects} setAllObjects={setAllObjects} setSelected={setSelection} hierarchyWidth={hierarchyWidth} setDragging={setDragging} existingJSON={existingJSON} setLoadingTemplate={setLoadingTemplate} setEnvironment={setEnvironment} disableHotkeys={disableHotkeys} />
            <TemplateViewer allObjects={allObjects} selected={selection} setSelected={setSelection} hierarchyWidth={hierarchyWidth} environment={environment} setEnvironment={setEnvironment} disableHotkeys={disableHotkeys} setDisableHotkeys={setDisableHotkeys} />
            <TemplateDetails setUploadType={setUploadType} allObjects={allObjects} onPreviewTemplate={onPreviewTemplate} />
            {uploadType && <UploadModal onClose={() => setUploadType(null)} uploadType={uploadType} setExportStatus={setExportStatus} selectedObjects={allObjects} environment={environment} />}
            {previewJSON && <PlayerPreview json={previewJSON} onClose={() => setPreviewJSON(null)} />}
        </Main>
        </Container>
    );
};

const Container = styled.div`
  z-index: 0;
  position: relative;
  height: 100vh;
  width: 100vw;
  min-width: 1000px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  // min-width: 1000px;
  height: calc(100vh - 56px);
`;

const ObjectDetails = styled.div`
    position: absolute;
    left: 245px;
    top: 60px;
    background-color: white;
    padding: 12px;
    border-radius: 5px;
`;

const Loading = styled.div`
  z-index: 3;
  position: absolute;
  height: calc(100vh - 56px);
  width: 100vw;
  top: 56px;
  background: #F5F5F5;
  display: flex;
  p {
    margin: auto;
    font-family: 'SkyTextMedium';
    font-size: 16px;
    text-align: center;
    color: #0072C9;
  }
`;
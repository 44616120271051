import styled from "styled-components";

export const MetadataTable = (props) => {
  return (
    <Grid>
      <ColumnA>
        <Icon
          src={props.icon}
          divider={props.iconDivider}
          style={props.iconStyle}
          theme={props.theme}
        />
        {props.property}
      </ColumnA>
      <ColumnB>{props.count}</ColumnB>
      <Spacer>|</Spacer>
      <ColumnC>{props.maxValue}</ColumnC>
    </Grid>
  );
};

const Grid = styled.div`
  font-size: 14px;
  display: flex;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 10px;
  grid-template-rows: auto;
  background: #fcfcfc;
`;

const Icon = styled.img`
  height: 14px;
  flex-grow: 0;
  padding-left: 0px;
  padding-right: 10px;
  transition: all 0.125s;
`;

const ColumnA = styled.div`
  font-family: "SkyTextRegular";
  align-items: left;
  // width: 120px;
  flex-grow: 1;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
`;

const ColumnB = styled.div`
  font-family: "SkyTextMedium";
  text-align: right;
  width: 70px;
  border-bottom: 1px solid #e1e1e1;
`;

const Spacer = styled.div`
  font-family: "SkyTextRegular";
  text-align: center;
  color: #d1d1d1;
  // flex-grow: 1;
  // width: 30px;
  padding: 0px 6px 0px 12px;
  border-bottom: 1px solid #e1e1e1;
`;
const ColumnC = styled.div`
  font-family: "SkyTextMedium";
  text-align: center;
  width: 70px;
  // flex-grow: 1;
  border-bottom: 1px solid #e1e1e1;
`;

import { Grid, OrbitControls, Select, TransformControls, useHelper, PerspectiveCamera, useBVH, Image, useAnimations, Environment, Torus, Sphere, Line, GizmoHelper, GizmoViewport, AdaptiveDpr } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BufferGeometry, CameraHelper, Color, DirectionalLightHelper, Layers, Light, Mesh, PerspectiveCamera as ThreePerspectiveCamera, PointLightHelper, SpotLightHelper, Vector3, Vector4, WebGLRenderer} from "three";
import ico_camera from "../assets/images/ico_camera.svg";
import ico_light from "../assets/images/ico_light.svg";
import { folder, Leva, useControls } from "leva";
import { Vector2 } from "three";
import { CustomEnvironment } from "./ui/organisms/CustomEnvironment";
import { getEnvironments } from "../utils/uploadUtils";
import { act } from "react-dom/test-utils";
import { MeshBVHVisualizer, computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from "three-mesh-bvh";

export const TemplateViewer = ({allObjects, selected, setSelected, hierarchyWidth, environment, setEnvironment, disableHotkeys, setDisableHotkeys}) => {
    const [transformMode, setTransformMode] = useState('translate');
    const [viewerSelection, setViewerSelection] = useState([]);
    const [previewViewport, setPreviewViewport] = useState(new Vector4(25,25,384,216));
    const [draggingViewport, setDraggingViewport] = useState(null);
    const [isTransforming, setIsTransforming] = useState(false);
    const [previewCamera, setPreviewCamera] = useState(null);
    const [environmentOptions, setEnvironmentOptions] = useState({});
    const [changingSelection, setChangingSelection] = useState(false);
    const [, setOrbitCheck] = useState(false);
    const [, setOrbitting] = useState(false);
    const [, setOrbitRadius] = useState(null);
    const [, setOrbitHeight] = useState(null);
    const [, setTitle] = useState(null);
    const [, setSynopsis] = useState(null);

    const [, setLightColor] = useState(null);
    const [, setLightIntensity] = useState(null);
    const [, setActiveLightType] = useState("point");
    const [, setLightDistance] = useState(null);
    const [, setLightAngle] = useState(null);
    const [, setLightPenumbra] = useState(null);

    const [orbitStart, setOrbitStart] = useState(null);

    const targetRef = useRef();
    const orbitControlsRef = useRef();

    BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    Mesh.prototype.raycast = acceleratedRaycast;

    useEffect(() => {
        setPreviewCamera(null);
        setViewerSelection([]);
        for (var i = 0; i < allObjects.length; i++) {
            if (selected[allObjects[i].uuid]) {
                setViewerSelection([allObjects[i]]);
                break;
            }
        }
    }, [selected, setViewerSelection, setPreviewCamera, allObjects]);

    useEffect(() => {
        const keyDownHandler = event => {
            if (!disableHotkeys) {
                if (event.key === "w") setTransformMode('translate');
                else if (event.key === "e") setTransformMode('rotate');
                else if (event.key === "r") setTransformMode('scale');
            }
        }
        document.addEventListener('keydown', keyDownHandler);

        getEnvironments().then(setEnvironmentOptions);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    },[disableHotkeys]);

    const active = viewerSelection[0];
    const selectObject = (e) => {
        var currentAngle = new Vector2(orbitControlsRef.current.getAzimuthalAngle(), orbitControlsRef.current.getPolarAngle());
        if (isTransforming) return;
        if (orbitStart && orbitStart.distanceTo(currentAngle) > 0.1) return;
        if (e.length > 0) {
            var newSelected = {};
            var child = e[0];
            while (!child.parent.isScene && child.parent.name !== "select") {
                child = child.parent;
            }
            newSelected[child.uuid] = true;
            setSelected(newSelected);
        }
        else {
            setChangingSelection(true);
            setSelected({});
        }
    }

    useEffect(() => {
        if (active) {
            if (active.lightType) setType("Light");
            else setType(active.type);
            setName(active.name);
        } else {
            setType('');
            setName('');
            setChangingSelection(true);
        }
    },[active]);

    const [,set] = useControls(() => ({
        name: { label: 'Name', value: '', disabled: true, render: (get) => get('type') !== '', },
        type: { value: '', disabled: true, render: (get) => false, },
        position: {
            label: 'Position',
            value: {x: 0, y:0,z:0},
            onChange: (value) => {
                !changingSelection && active && targetRef.current.object === active && active.position.set(value.x, value.y, value.z);
            },
            render: (get) => get('type') !== '',
        },
        rotation: {
            label: 'Rotation',
            value: {x: 0, y:0,z:0},
            onChange: (value) => {
                !changingSelection && active && targetRef.current.object === active  && active.rotation.set(value.x, value.y, value.z);
            },
            render: (get) => get('type') !== '',
        },
        scale: {
            label: 'Scale',
            value: {x: 1, y:1,z:1},
            onChange: (value) => {
                !changingSelection && active && targetRef.current.object === active  && active.scale.set(value.x, value.y, value.z);
            },
            render: (get) => get('type') !== '' && !get('type').includes('Camera') && !get('type').includes('Light') ,
        },
        fov: {
            label: 'FOV',
            value: 70,
            min: 0,
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active  && active instanceof ThreePerspectiveCamera) {
                    active.fov = value;
                    active.updateProjectionMatrix();
                }
            },
            render: (get) => get('type').includes('Camera')
        },
        cameraType: {
            value: "fixed",
            label: "Type",
            options: ["fixed", "orbit"],
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active  && active instanceof ThreePerspectiveCamera) {
                    active.cameraType = value;
                }
            },
            render: (get) => get('type').includes('Camera')
        },
        orbitRadius: {
            label: "Radius",
            value: 1,
            min: 0,
            render: (get) => get('type').includes('Camera') && get('cameraType') === "orbit",
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active  && active instanceof ThreePerspectiveCamera) {
                    setOrbitRadius(value);
                    active.orbitRadius = value;
                }
            }
        },
        orbitHeight: {
            label: "Height",
            value: 0,
            render: (get) => get('type').includes('Camera') && get('cameraType') === "orbit",
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active  && active instanceof ThreePerspectiveCamera) {
                    setOrbitHeight(value);
                    active.orbitHeight = value;
                }
            }
        },
        title: {
            label: "Title",
            value: "",
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active && active instanceof ThreePerspectiveCamera) {
                    setTitle(value);
                    active.title = value;
                }
            },
            onEditStart: () => {setDisableHotkeys(true)},
            onEditEnd: () => {setDisableHotkeys(false)},
            render: (get) => get('type').includes('Camera')
        },
        synopsis: {
            label: "Synopsis",
            value: "",
            rows: 3,
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active && active instanceof ThreePerspectiveCamera) {
                    setSynopsis(value);
                    active.synopsis = value;
                }
            },
            onEditStart: () => {setDisableHotkeys(true)},
            onEditEnd: () => {setDisableHotkeys(false)},
            render: (get) => get('type').includes('Camera')
        },
        lightType: {
            value: "point",
            label: "Type",
            options: ["point", "directional", "spot"],
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active  && active.lightType) {
                    setActiveLightType(value);
                    active.lightType = value;
                }
            },
            render: (get) => get('type').includes('Light')
        },
        color: {
            label: "Color",
            value: '#fff',
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active  && active.lightType) {
                    setLightColor(value);
                    active.color = value;
                }
            },
            onEditStart: () => {setDisableHotkeys(true)},
            onEditEnd: () => {setDisableHotkeys(false)},
            render: (get) => get('type').includes('Light')
        },
        intensity: {
            label: "Intensity",
            value: 1,
            min: 0,
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active && active.lightType) {
                    setLightIntensity(value);
                    active.intensity = value;
                }
            },
            render: (get) => get('type').includes('Light')
        },
        distance: {
            label: "Distance",
            value: 1,
            min: 0,
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active && active.lightType) {
                    setLightDistance(value);
                    active.distance = value;
                }
            },
            render: (get) => get('type').includes('Light') && (get('lightType') === "spot" || get('lightType') === "point")
        },
        angle: {
            label: "Angle",
            value: 1,
            min: 0,
            max: Math.PI/2,
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active && active.lightType) {
                    setLightAngle(value);
                    active.angle = value;
                }
            },
            render: (get) => get('type').includes('Light') && get('lightType') === "spot",
        },
        penumbra: {
            label: "Penumbra",
            value: 0.5,
            min: 0,
            max: 1,
            onChange: (value) => {
                if (!changingSelection && active && targetRef.current.object === active && active.lightType) {
                    setLightPenumbra(value);
                    active.penumbra = value;
                }
            },
            render: (get) => get('type').includes('Light') && get('lightType') === "spot"
        },
    }),
    [active, changingSelection, targetRef.current]);

    const setPosition = ({x,y,z}) => set({position: {x,y,z}});
    const setRotation = ({x,y,z}) => set({rotation: {x,y,z}});
    const setScale = ({x,y,z}) => set({ scale: {x,y,z}});
    const setType = (v) => set({ type: v});
    const setName = (v) => set({ name: v});

    const setLightType = (v) => set({lightType: v});
    const setColor = (v) => set({color: v});
    const setIntensity = (v) => set({intensity: v});
    const setDistance = (v) => set({distance: v});
    const setAngle = (v) => set({angle: v});
    const setPenumbra = (v) => set({penumbra: v});

    const setFOV = (v) => set({fov : v});

    // const setEnvironmentName = (v) => set({image: v});
    // const setEnvironmentRotation = (v) => set({rot: v});
    // const setEnvironmentShadows = (v) => set({castShadows: v});

    // useEffect(() => {
    //     if (environment && environment.id && environmentOptions) {
    //         Object.keys(environmentOptions).forEach((name) => {
    //             if (environmentOptions[name].id === environment.id) {
    //                 set({
    //                     image: environmentOptions[name],
    //                     rot: environment.rotation,
    //                     castShadows: environment.castShadows
    //                 });
    //             }
    //         })
    //     }
    // }, [environment, environmentOptions]);

    const setOrbitRadiusControl = (v) => set({orbitRadius: v});
    const setOrbitHeightControl = (v) => set({orbitHeight: v});
    const setCameraType = (v) => set({cameraType: v});

    const setCameraTitle = (v) => set({title: v});
    const setCameraSynopsis = (v) => set({synopsis: v});

    const updateTargetTransform = (object) => {
        setPosition({x: object.position.x, y: object.position.y, z: object.position.z});
        setRotation({x: object.rotation.x, y: object.rotation.y, z: object.rotation.z});
        setScale({x: object.scale.x, y: object.scale.y, z: object.scale.z});

        if (object.lightType) {
            setLightType(object.lightType);
            if (object.color) setColor(object.color);
            if (object.intensity) setIntensity(object.intensity);
            if (object.distance) setDistance(object.distance);
            if (object.angle) setAngle(object.angle);
            if (object.penumbra) setPenumbra(object.penumbra);
        }

        if (object instanceof ThreePerspectiveCamera) {
            setFOV(object.fov);
            setCameraType(object.cameraType ? object.cameraType : "fixed");
            if (object.cameraType && object.cameraType === "orbit") {
                setOrbitRadiusControl(object.orbitRadius);
                setOrbitHeightControl(object.orbitHeight);
            } else {
                setOrbitRadiusControl(0);
                setOrbitHeightControl(0);
            }
            setCameraTitle(object.title ? object.title : "");
            setCameraSynopsis(object.synopsis ? object.synopsis : "");
        }

        setChangingSelection(false);
    }

    const lightTheme = {
        colors: {
            elevation1: '#f2f2f2',
            elevation2: '#ffffff',
            elevation3: '#f7f7f7',
            accent1: '#ccc',
            accent2: '#e6e6e6',
            accent3: '#ccc',
            highlight1: '#b3b3b3',
            highlight2: '#000',
            highlight3: '#000',
          },
        fonts: {
            mono: "SkyTextRegular",
            sans: "SkyTextRegular",
        }
    }

    return (
        <Scene 
            hierarchyWidth={hierarchyWidth} 
            onMouseMove={(e) => {
                if (draggingViewport) {
                    setPreviewViewport(new Vector4(Math.min(Math.max(5, e.clientX - hierarchyWidth - draggingViewport.x), window.innerWidth - hierarchyWidth - 320 - previewViewport.z - 5), Math.min(window.innerHeight - 56 - 5 - previewViewport.w, Math.max(5, window.innerHeight - e.clientY - draggingViewport.y)), previewViewport.z, previewViewport.w));
                }
            }}
            onMouseUp={(e) => {
                setDraggingViewport(null);
                setOrbitCheck(false);
                setOrbitting(false);
            }}
            onMouseDown={(e) => {
                setOrbitting(false);
                setOrbitCheck(true);
            }}
        >
            <LevaContainer>
                <Leva theme={lightTheme} fill titleBar={false} />
            </LevaContainer>
            {active instanceof ThreePerspectiveCamera && <CameraContainer viewport={previewViewport} borderWidth={5} onMouseDown={(e) => {setDraggingViewport(new Vector2(e.clientX - hierarchyWidth - previewViewport.x, window.innerHeight - e.clientY - previewViewport.y))}} />}
            <Canvas>
                <AdaptiveDpr />
                <CustomEnvironment environment={environment} setEnvironment={setEnvironment} selected={!active} />
                <PreviewRender previewCamera={previewCamera} previewViewport={previewViewport} />
                <PerspectiveCamera makeDefault fov={70} position={[10,10,10]} />
                <Select onChange={selectObject} name="select">
                    {allObjects.map(object => {
                        if (object instanceof ThreePerspectiveCamera) return <CameraWithHelper showHelper={active === object} object={object} key={object.uuid} setPreview={setPreviewCamera} />;
                        else if (object.lightType) return <LightWithHelper object={object} key={object.uuid} showHelper={active === object} />;
                        else return <Model object={object} key={object.uuid} selected={active === object} />;
                    })}
                </Select>
                {active && <TransformControls ref={targetRef} object={active} mode={transformMode} onChange={(v)=>{v.target && v.target.object && updateTargetTransform(v.target.object)}} onMouseDown={() => setIsTransforming(true)} onMouseUp={() => {setTimeout(()=>{setIsTransforming(false)},50)}} />}
                <Grid infiniteGrid={true} cellSize={1} sectionSize={5} sectionColor={"white"} frustumCulled={false} followCamera={false} fadeDistance={100} />
                <OrbitControls ref={orbitControlsRef} makeDefault onStart={(e) => {setOrbitStart(new Vector2(e.target.getAzimuthalAngle(), e.target.getPolarAngle()))}}/>
                {/* <GizmoHelper alignment="bottom-right" autoClear>
                    <GizmoViewport axisColors={['#ef869c', '#cbee4b', '#87c3fa']} labelColor="black" />
                </GizmoHelper> */}
            </Canvas>
        </Scene>
    );
}

const PreviewRender = ({previewCamera, previewViewport = new Vector4()}) => {
    useThree();
    var bg = new Color('#333333');
    useFrame(({gl, camera, scene}) => {
        gl.autoClear = false;
        var viewport = new Vector4();
        gl.getViewport(viewport);
        gl.setViewport(viewport);
        gl.setScissorTest(false);
        gl.setRenderTarget(null);
        gl.render(scene, camera);
        if (previewCamera) {
            gl.clear(false,true,true);
            gl.setScissorTest(true);
            gl.setScissor(previewViewport);
            gl.setViewport(previewViewport);
            gl.render(scene, previewCamera);
        }
        gl.setViewport(viewport);
        gl.autoClear = true;
    }, 1);

    return <></>;
}

const Model = ({object}) => {
    const {ref, actions} = useAnimations(object.animations);
    useEffect(() => {
        const layer = new Layers();
        layer.enable(1);
        object.traverse(function(node) {
            node.layers = layer;
            if (node instanceof Mesh) {
                node.geometry.computeBoundsTree();
            }
        });
        if (actions) {
            Object.keys(actions).forEach((name, index) => {
                if (name.toLowerCase().includes('idle') || (Object.keys(actions).length === 1 && index === 0)) {
                    actions[name].play();
                }
            })
        }
    }, [object]);

    return <primitive object={object} key={object.uuid} ref={ref} />;
}

const CameraWithHelper = ({object, showHelper, setPreview}) => {
    const camera = useRef();
    const orbitCamera = useRef();

    useHelper(showHelper && object.cameraType && (object.cameraType === "orbit" ? orbitCamera : camera), CameraHelper);

    useEffect(() => {
        if (showHelper) {
            setPreview(object.cameraType && object.cameraType === "orbit" ? orbitCamera.current : camera.current);
        }
    }, [showHelper, setPreview, object.cameraType]);
    
    const layer = new Layers();
    layer.disable(0);
    layer.enable(1);

    useFrame(() => {
        if (showHelper && object.cameraType === "orbit") {
            orbitCamera.current.position.set(0,object.orbitHeight,-object.orbitRadius);
            orbitCamera.current.lookAt(object.position);
            if (orbitCamera.current.fov !== object.fov) {
                orbitCamera.current.fov = object.fov;
            }
            if (orbitCamera.current.aspect !== object.aspect) {
                orbitCamera.current.aspect = object.aspect;
            }

                orbitCamera.current.updateProjectionMatrix();
        }
    })

    return (
        <>
        <primitive object={object} ref={camera} layers={layer}>
            <perspectiveCamera ref={orbitCamera} layers={layer} />
            <HelperIcon icon={ico_camera} scale={0.5}  />
            {showHelper && object.cameraType && object.cameraType === "orbit" && <Torus args={[object.orbitRadius, 0.01, 4, 60]} rotation={[Math.PI/2,0,0]} position={[0,object.orbitHeight,0]} material-color={"red"} />}
            {showHelper && object.cameraType && object.cameraType === "orbit" && <Line points={[[0,0,0],[0, object.orbitHeight, -object.orbitRadius]]} color='red' lineWidth={3} />}
        </primitive>
        </>  
    );
}

const LightWithHelper = ({object, showHelper}) => {

    const layer = new Layers();
    layer.enable(1);

    const lightRef = useRef();
    const spotLightTargetRef = useRef();

    useHelper(showHelper && object.lightType && lightRef, object.lightType === "directional" ? DirectionalLightHelper : object.lightType === "spot" ? SpotLightHelper : PointLightHelper);

    return <primitive object={object} layers={layer}>
        {object.lightType === "point" && <pointLight position={[0,0,0]} ref={lightRef} color={object.color} intensity={object.intensity} distance={object.distance} layers={layer} />}
        {object.lightType === "directional" && <directionalLight position={[0,0,0]} ref={lightRef} color={object.color} intensity={object.intensity} layers={layer} />}
        {object.lightType === "spot" && <spotLight position={[0,0,0]} ref={lightRef} color={object.color} intensity={object.intensity} distance={object.distance} target={spotLightTargetRef.current} angle={object.angle} penumbra={object.penumbra} layers={layer} />}
        <object3D position={[0,-1,0]} ref={spotLightTargetRef} />
        <HelperIcon icon={ico_light} scale={0.5} />
    </primitive>
}

const HelperIcon = ({icon, scale}) => {
    const iconRef = useRef();
    const layer = new Layers();
    layer.enable(2);
    useFrame(({camera}) => {
        iconRef.current && iconRef.current.lookAt(camera.position);
    });
    return <Image url={icon} scale={scale} ref={iconRef} transparent opacity={.9} layers={layer} />;
}

const Scene = styled.div`
  z-index: 0;
  aspect-ratio: ${(props) => props.aspect};
  min-width: ${(props) => Math.max(400, 1000-320-props.hierarchyWidth)}px;
  width: calc(100vw - 320px - ${(props) => props.hierarchyWidth}px);
  position: relative;
  display: flex;
`;

const LevaContainer = styled.div`
     position: absolute;
     z-index: 10;
     z-index: 20;
     right: 0;
     top: 0;
     padding: 6px;
     width: 250px;
     height: fit-content;

     .leva-c-hBtFDW {
         height: fit-content !important;
     }
 `;

const CameraContainer = styled.div`
    position: absolute;
    z-index: 10;
    left: ${props => props.viewport.x - props.borderWidth}px;
    bottom: ${props => props.viewport.y - props.borderWidth}px;
    width: ${props => props.viewport.z}px;
    height: ${props => props.viewport.w}px;
    border: ${props => props.borderWidth}px solid white;
    border-radius: 4px;
`;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

Providers.globalProvider = new Msal2Provider({
  clientId: '13b6ad96-efd3-4721-b7c4-087a346094eb',
  authority: 'https://login.microsoftonline.com/skyglobal.onmicrosoft.com',
  scopes: ['user.read', 'files.readwrite.all', 'sites.readwrite.all']
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
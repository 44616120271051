/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ico_login from "../assets/images/ico_login.png";
import ico_error from "../assets/images/ico_error.png";
import ico_close from "../assets/images/ico_close.svg";
import ico_plus from "../assets/images/ico_plus.svg";
import { Providers } from '@microsoft/mgt-element';
import { FileBrowser } from "../components/ui/organisms/FileBrowser";
import modelStore from "../lib/stores/store";
import { Loader } from "../utils/loader";
import { optimiseModel } from "../utils/modelOptimisation";
import ModalFileUploader from "../components/ui/organisms/ModalFileUploader";

export const LoaderPage = ({signedIn, userDetails}) => {
  const navigate = useNavigate();
  const [loadedModel, setLoadedModel] = useState(null);
  const [loadError, setLoadError] = useState([]);
  const [filename, setFilename] = useState("");
  const {setModelStore, setMasterModelStore, setModelNameStore, setGraphIdStore, setMasterFileStore, setTemplateIdStore} = modelStore();

  const CATCH_WARNINGS = [
    'FBXLoader: Image type "fbm" is not supported.'
  ];

  const addError = (error) => {
    if (CATCH_WARNINGS.includes(error)) {
      setLoadError(loadError => 
        loadError.includes(error) ? [...loadError] : 
        [...loadError, error]);
    }
  }

  const loader = new Loader({setScene: setLoadedModel, setName: setFilename, setError: addError});

  useEffect(() => {
    if (loadError.length === 0) {
      if (loadedModel) {
        setModelNameStore(filename);
        setGraphIdStore(null);
        setMasterModelStore(loadedModel);
        setModelStore(optimiseModel(loadedModel));
        modelUploaded();
      } else {
        setModelNameStore("");
        setMasterModelStore(null);
        setModelStore(null);
        setGraphIdStore(null);
        setLoadError([]);
      }
    }
  }, [loadedModel]);

  if (!signedIn) {
    return <Navigate to={"/login"} />;
  }
  
  const modelUploaded = () => {
    navigate("/viewer");
  };

  const existingModelSelected = (file) => {
    if (file.name.includes(".json")) {
      setTemplateIdStore(file.id);
      setModelNameStore(file.name);
      navigate("/template");
    } else {
      setModelNameStore(file.name);
      setGraphIdStore(file.id);
      setMasterModelStore({url: file["@microsoft.graph.downloadUrl"], name: file.name});
      modelUploaded();
    }
  }

  const newModelSelected = (file) => {
    setMasterFileStore(file[0]);
    setLoadError([]);
    loader.loadFiles(file);
  }

  return (
    <div id="signedInView">
      <ContainerTop>
        <Logo url="assets/images/sky_logo.png"></Logo>
        {userDetails && <UserLabel>{userDetails.displayName}</UserLabel>}
        <ButtonClose
          onClick={() => {
            Providers.globalProvider.logout();
          }}
        >
          <Label>Sign out</Label>
          <Icon src={ico_login} />
        </ButtonClose>
      </ContainerTop>
      <PageWrapper>
        <Columns>
        <Container style={{justifyContent: "space-between", minWidth: "400px"}}>
          <Container2>
            <Title><p>Upload 3D model</p></Title>
              <Content>
                <ModalFileUploader onUpload={newModelSelected} />

                {loadError.map((element, index) => 
                  <ErrorContainer key={index}>
                    <img alt={"Error Symbol"} src={ico_error} />
                    <p>{element}</p>
                  </ErrorContainer>)}
                <Text>
                  Axis inspector accepts the following file formats:<br /> FBX, OBJ, GLB,
                  GLTF
                </Text>
              </Content>
          </Container2>
          <Container2>
            <Title><p>Create a scene</p></Title>
              <Content>
                <AddButton onClick={() => {setTemplateIdStore(null);navigate("/template");}}>
                  <Icon src={ico_plus} />
                  <p style={{marginBottom: "0px", marginTop: "2px"}}>Create a new scene using approved models</p>
                </AddButton>
              </Content>
          </Container2>
        </Container>
        <Container style={{minWidth: "300px"}}>
          <Title><p>Browse</p></Title>
          <Content>
            <FileBrowser
              style={{height: "calc(100% - 24px)"}}
                onClick={existingModelSelected}
                baseDir={"Assets"}
                siteId={
                  "97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1"
                }
                thumbnails
              />
          </Content>
        </Container>

        </Columns>
        
      </PageWrapper>
    </div>
  );
};

const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background: #ffffff;
  z-index: 2;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.05);
  min-width: 750px;
  position: absolute;
`;

const UserLabel = styled.div`
  margin-left: auto;
  padding: 0px 12px;
  line-height: 56px;
  font-family: "SkyTextRegular";
  font-size: 16px;
  border-left: 1px solid #F5F5F5;
  overflow: hidden;
`;

const Label = styled.div`
  color: rgba(74, 74, 74, 0.75);
`;

const Text = styled.div`
  font-family: "SkyTextRegular";
  font-size: 11px;
  padding-top: 20px;
  width: auto;
  border-left: 1px solid #f5f5f5;
  text-align: center;
`;
const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 0px 5px;
  border-right: 1px solid #f5f5f5;
  background: url(${(props) => props.url});
  background-position: 50% 55%;
  background-repeat: no-repeat;
  background-size: 43.75px;
`;

const Icon = styled.img`
  // display: flex;
  // align-items: center;
  width: 14px;
  height: 17px;
  padding: 
  // cursor: pointer;
`;
const ButtonClose = styled.div`  
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0px 24px;
  line-height: 56px;
  font-family: "SkyTextRegular";
  font-size: 16px;
  border-left: 1px solid #F5F5F5;
  overflow: hidden;
  cursor: pointer;

  :hover ${Icon}, :hover ${Label} {
    filter: invert(27%) sepia(89%) saturate(1730%) hue-rotate(188deg)
      brightness(95%) contrast(101%);
  }
`;

const Title = styled.h1`
background: #f9f9f9;
padding: 16px 72px 4px 24px;
margin: 0;
border-radius: 12px 12px 0 0;
p {
  font-family: "SkyTextRegular";
  font-size: 28px;
  margin: 0;
  background-image: linear-gradient(90deg, #FF9421 0%, #F2029C 27.08%, #C400D9 51.04%, #8A02F5 76.56%, #235BFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
align-self: start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-stretch;
  padding: 18px 18px 16px;
  width: 40%;
  top: 96px;
  background: #fcfcfc;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 12px;
`;

const Container2 = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: flex-stretch;
  top: 96px;
`;

const AddButton = styled.div`
display: flex;
flex-direction: column;
gap: 12px;
justify-content: center;
align-items: center;
text-align: center;
line-height: 1.5;
padding: 44px;
background: white;
border-radius: 8px 8px 8px 8px;
align-self: stretch;
cursor: pointer;

${Icon} {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

p {
  font-family: "SkyTextRegular";
  font-size: 14px;
  font-weight: "500";
  max-width: 250px;
  background: linear-gradient(90deg, #FF9421 0%, #F2029C 27.08%, #C400D9 51.04%, #8A02F5 76.56%, #235BFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
`;

const PageWrapper = styled.div`
position: absolute;
top: 56px;
padding: 52px 0 0 0;
width: 100%;
min-width: 750px;
min-height: 800px;
height: calc(100vh - 56px - 52px);
background-color: #f5f5f5;
overflow: auto;
`;

const Columns = styled.div`
  display: flex;
  height: 75%;
  min-height: 736px;
  gap: 32px;
  padding: 0 5%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${Container} {
    flex: 1 0 auto;
    height: 100%;
  }

  ${Container}: first-child {
    flex: 2;
  }

  ${Container}: last-child {
    flex: 1;
  }
`;

const Content = styled.div`
  padding: 24px 32px;
  height: 100%;
  background: #f9f9f9;
  border-radius: 0px 8px 8px 8px;
  overflow: hidden;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  margin: 12px 0 0 0;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: #FFFFFF;
  border: 1px solid #FF6240;
  border-radius: 4px;

  font-family: 'SkyTextRegular';
  font-size: 14px;
`;
import { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled from "styled-components";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";

export const PlayerPreview = ({json, onClose}) => {

    const {unityProvider, sendMessage, isLoaded, unload} = useUnityContext({
        loaderUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.loader.js",
        dataUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.data",
        frameworkUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.framework.js",
        codeUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.wasm",
      });

      useEffect(() => {
        if (isLoaded && json && json !== {}) sendMessage("Home", "LoadPreviewJson", JSON.stringify(json));
      }, [json, sendMessage, isLoaded]);

      const closePreview = () => {
        unload().then(() => {
            onClose();
        })
      }

    return(
        <Container>
            <FullscreenDarken />
            <UnityModal theme="blue">
                {isLoaded === false && <LoadingSpinner style={{marginTop: "-12px", borderRadius: "4px"}} />}
                <Unity unityProvider={unityProvider} style={{width: "100%", borderRadius: "4px", marginBottom: "0" , padding: 0, visibility: isLoaded ? "unset" : "hidden"}} />
                <ButtonsContainer>
                <ButtonContainer>
                    <ButtonPrimary
                        style={{padding: "10px 18px", cursor: "pointer"}}
                        text={"Close Preview"}
                        theme={"white"}
                        onClick={closePreview}
                    />
                </ButtonContainer>
            </ButtonsContainer>
            <p style={{order: 3, fontSize: "12px", padding: "0px"}}>* Transparent materials may appear opaque in preview. This does not affect Axis Player.</p>
            </UnityModal>
        </Container>
    )
}

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    min-height: 750px;
    gap: 12px;
`;

const FullscreenDarken = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.7;
    z-index: 3;
`;

const UnityModal = styled.div`
    position: relative;
    background: #fcfcfc;
    border-top: 14px solid ${props => props.theme === "blue" ? "#0072c9" : "#ff6240"};
    height: fit-content;
    max-height: fit-content;
    opacity: 1;
    z-index: 4;
    filter: drop-shadow(0px 0px 20px rgba(51, 51, 51, 0.05));
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 12px;
    margin-top: 56px;
    font-family: "SkyTextRegular";
    transition: all 1s;
    min-width: 484px;
    width: 75%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    order: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;
import { Environment } from "@react-three/drei";
import { useEffect, useState } from "react";
import { BackSide, LinearFilter, RGBAFormat, TextureLoader } from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import { DEG2RAD } from "three/src/math/MathUtils";
import { getEnvironments } from "../../../utils/uploadUtils";
import { useControls } from "leva";

const EnvironmentControls = ({environment, environmentOptions, setEnvironment}) => {
    const [,setControls] = useControls(() => ({
        image: {
            label: "HDR",
            options: {'None': null, ...environmentOptions},
            onChange: (value) => {
                setEnvironment({...value, rotation: 0});
            }
        },
        rot: {
            value: 0,
            min: 0,
            max: 360,
            label: 'Rotation',
            onChange: (value) => {
                setEnvironment({...environment, rotation: value});
            }
        },
        castShadows: {
            value: false,
            label: 'Shadows',
            onChange: (value) => {
                setEnvironment({...environment, castShadows: value});
            }
        }
    }),[environment, environmentOptions, setEnvironment]);

    useEffect(() => {
        if (environment && environment.id && environmentOptions) {
            Object.keys(environmentOptions).forEach((name) => {
                if (environmentOptions[name].id === environment.id) {
                    setControls({
                        image: environmentOptions[name],
                        rot: environment.rotation,
                        castShadows: environment.castShadows
                    });
                }
            })
        }
    }, [environment, environmentOptions, setControls]);
}

export const CustomEnvironment = ({environment, setEnvironment, selected}) => {
    const [environmentOptions, setEnvironmentOptions] = useState({});

    useEffect(() => {
        getEnvironments().then(setEnvironmentOptions);
    }, [])

    const [lastUrl, setLastUrl] = useState(null);
    const [texture, setTexture] = useState(null);

    useEffect(() => {
        if (environment && environment.url) {
            if (environment.url !== lastUrl) {
                setTexture(null);
                var loader;
                if (environment.extension === 'exr') {
                    loader = new EXRLoader();
                } else if (environment.extension === 'hdr') {
                    loader = new RGBELoader();
                } else {
                    loader = new TextureLoader();
                }
                loader.loadAsync(environment.url).then((res) => {
                    res.format = RGBAFormat;
                    res.minFilter = LinearFilter;
                    res.magFilter = LinearFilter;
                    setLastUrl(environment.url);
                    setTexture(res);
                });
            }
        } else {
            setTexture(null);
        }
    }, [environment, lastUrl]);

    return (
        <>
        {texture ? 
        <Environment background resolution={512}>
            <mesh rotation={[0,environment.rotation * DEG2RAD,0]}>
                <sphereGeometry args={[100]} />
                <meshBasicMaterial map={texture} side={BackSide} />
            </mesh>
        </Environment>
        :
        <color attach="background" args={["#333333"]} />}
        {selected && <EnvironmentControls environment={environment} environmentOptions={environmentOptions} setEnvironment={setEnvironment} />}
        </>
    );
}
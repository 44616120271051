import styled from "styled-components";
import ico_copy from "../../../assets/images/ico_copy.png";
import ico_tick from "../../../assets/images/ico_tick.png";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import { useState } from "react";

export const ModalSave = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyModelID = () => {
    navigator.clipboard.writeText(props.secondaryText).then();
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    setIsCopied(true);

    // var file = "/Users/mvs03/Downloads/SpecDevices_v1.xlsx";
    // var XLSX = require("xlsx");
    // var workbook = XLSX.readFile(file);
    // var sheet_name_list = workbook.SheetNames;
    // var xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
    // console.log(xlData);
  };
  return (
    <>
      {/* <Head>
        <Title theme={props.theme}>{props.title}</Title>
      </Head> */}
      <BoxContainer style={props.style} theme={props.theme}>
        <Toast style={{visibility: isCopied ? "visible" : "hidden", ...props.toastStyle}}>
          Copied
          <Icon src={ico_tick} />
        </Toast>
        <TextA>{props.text}</TextA>
        <TextB>{props.secondaryText}</TextB>
        <ButtonPrimary
          text={"Copy"}
          style={{ width: "86px", height: "26px", cursor: "pointer", ...props.buttonStyle }}
          icon={ico_copy}
          iconStyle={{ height: "10x", marginRight: "auto" }}
          theme={"white"}
          fontsize={"sm"}
          onClick={() => {
            copyModelID();
          }}
        />
      </BoxContainer>
    </>
  );
};
const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 0px;
  background: #fcfcfc;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-shrink: 1;
`;
const Title = styled.h2`
  font-family: "SkyTextRegular";
  font-size: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 8px 1px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: ${(props) => {
    switch (props.theme) {
      case "green":
        return "#41C90A";
      case "red":
        return "#FF6240";
      case "blue":
        return "#0072C9";
      default:
        return "unset";
    }
  }};
`;
const BoxContainer = styled.div`
  text-align: left;
  font-size: 12px;
  line-height: 2;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  padding-top: 20px;
  padding-bottom: 25px;
  padding-left: 10px;
  background: #f5f5f5;
`;
const TextA = styled.div`
  font-family: "SkyTextRegular";
`;
const TextB = styled.div`
  font-family: "SkyTextMedium";
  padding-bottom: 8px;
`;

const Toast = styled.div`
  position: absolute;
  font-size: 12px;
  font-family: "SkyTextRegular";
  top: 78px;
  right: 14px;
  width: 81px;
  height: 20px;
  //   text-align: center;
  vertical-align: sub;
  opacity: 1;
  background-color: #cccccc;
  color: #333333;
  border-radius: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  
`;

const Icon = styled.img`
  position: absolute;
  padding-top: 3px;
  padding-left: 16px;
`;

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ico_close from "../../../assets/images/ico_close_2.svg";

const NavTop = (props) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Logo url="assets/images/sky_logo.png"></Logo>
      <ProductTitle>
        <Label>File Name</Label>
        <Title>{props.productName}</Title>
      </ProductTitle>
      <ButtonClose
        onClick={() => {
          navigate("/");
        }}
      >
        Close
        <Icon src={ico_close} />
      </ButtonClose>
    </Container>
  );
};
export default NavTop;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  background: #ffffff;
  z-index: 2;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.05);
`;
const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 0px 5px;
  border-right: 1px solid #f5f5f5;
  background: url(${(props) => props.url});
  background-position: 50% 55%;
  background-repeat: no-repeat;
  background-size: 43.75px;
`;
const ProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: -webkit-fill-available;
  justify-content: center;
  gap: 2px;
`;
const Label = styled.div`
  font-family: "SkyTextRegular";
  font-size: 14px;
`;
const Title = styled.div`
  font-family: "SkyTextMedium";
  font-size: 18px;
`;
const Icon = styled.img`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const ButtonClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid #f5f5f5;
  padding: 0 24px;
  font-family: 'SkyTextRegular';
  gap: 12px;

  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(74, 74, 74, 0.75);

  :hover {
    color: #3f94d6;
  }

  :hover ${Icon} {
    filter: invert(27%) sepia(89%) saturate(1730%) hue-rotate(188deg)
      brightness(95%) contrast(101%);
  }
`;

import {  Get } from "@microsoft/mgt-react";
import styled from "styled-components";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import ico_reload from "../../../assets/images/ico_reload.svg";
import ico_back from "../../../assets/images/ico_back.svg";
import { Fragment, useEffect, useState } from "react";
import ico_file from "../../../assets/images/ico_file.svg";
import ico_folder from "../../../assets/images/ico_folder.svg";

const Breadcrumbs = (props) => {

    const [crumbs, setCrumbs] = useState([]);

    useEffect(() => {
        setCrumbs(props.filePath.split("/"));
    }, [props.filePath]);

    const changeDirectory = (index) => {
        props.setFilePath(crumbs.slice(0, index + 1).join("/"));
    }

    return (
        <BreadcrumbContainer>
            {crumbs &&
                crumbs.map((dir, index) => {
                    if (index < props.baseIndex) return null;

                    if (props.maxCrumbs && (crumbs.length - props.baseIndex) > props.maxCrumbs) {
                        if (index === props.baseIndex + 1) return <Fragment key={index}><p>{">"}</p><p>...</p></Fragment>;
                        else if (index > props.baseIndex && index < crumbs.length - (props.maxCrumbs - 1)) return null;
                    }

                    return (
                        <Fragment key={index}>
                            {index !== props.baseIndex && <p>{">"}</p>}
                            <Breadcrumb onClick={()=>{index !== crumbs.length - 1 && changeDirectory(index)}}>
                                {dir}
                            </Breadcrumb>
                        </Fragment>
                    );
                })
            }
        </BreadcrumbContainer>
    );
}

const File = (props) => {
    return (
        <FileContainer onClick={() => {props.onClick && props.onClick(props)}} style={{cursor: props.onClick ? "pointer" : "default"}}>
            <FileIcon src={(props.thumbnails && props.thumbnails.length > 0 && !props.name.includes(".json")) ? props.thumbnails[0].small.url : props.folder ? ico_folder : ico_file} />
            <FileDetails>
                <div>{props.name}</div>
                {!props.folder && <div>{props.lastModifiedDateTime.split("T")[0].split("-").reverse().join("/")}</div>}
            </FileDetails>
        </FileContainer>
    );
}

export const FileBrowser = ({baseDir, siteId, onPathChanged, onError, onLoaded, thumbnails, onClick, style, fileTypes = ["glb", "gltf", "fbx", "obj", "json"]}) => {

    const baseIndex = baseDir.split("/").length - 1;
    const [filePath, setFilePath] = useState(baseDir);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [forceReload, setForceReload] = useState(false);
    const [error, setError] = useState(false);

    const fileSelected = (file) => {
        if (file && file.folder) {
            setFilePath(`${filePath}/${file.name}`);
        } else if (file && !file.folder && onClick) {
            onClick(file);
        }
    }

    const goBack = () => {
        const split = filePath.split("/");
        if (split.length > baseIndex + 1) {
            split.pop();
            setFilePath(split.join("/"));
        }
    }

    const reload = () => {
        setForceReload(true);
        setError(false);
        setLoading(true);
        setTimeout(() => {setForceReload(false)}, 500);
    }

    const updateResponse = (e) => {
        if (e.detail.error) {
            console.log(e.detail.error);
            setError(true);
            if (onError) onError();
            setLoading(false);
        } else {
            if (e.detail && e.detail.response && e.detail.response.value) {
                setFiles(e.detail.response.value.filter((file) => {
                    return file.folder ||  fileTypes.includes(file.name.split(".").pop());
                }));
                if (onLoaded) onLoaded();
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        if (onPathChanged) onPathChanged(filePath);
    }, [filePath, onPathChanged])

    return (
        <Container style={style}>
            <Get resource={!forceReload && `/sites/${siteId}/drive/root:/${filePath}:/children${thumbnails ? "?expand=thumbnails" : ""}`} dataChange={updateResponse} maxPages={0}/>
            <Breadcrumbs baseIndex={baseIndex} filePath={filePath} setFilePath={setFilePath} maxCrumbs={4} />
            <TopBar>
                <ButtonPrimary
                    text={""}
                    style={{ cursor: "pointer", padding: "12px" }}
                    theme={"white"}
                    icon={ico_back}
                    iconStyle={{ height: "14px", padding: "unset" }}
                    onClick={goBack}
                />
                <ButtonPrimary
                    text={"Reload"}
                    style={{ padding: "10px 16px", fontFamily: "SkyTextMedium", cursor: "pointer" }}
                    theme={"white"}
                    icon={ico_reload}
                    iconStyle={{ height: "14px" }}
                    onClick={reload}
                />
            </TopBar>
            <FilesContainer>
                {error ? <Error>Couldn't load files.<p>Check your internet connection and try reloading the widget.</p></Error> :
                loading ? <Loading>Loading...</Loading> :
                    files && files.map((file, index) => {
                        return <File key={index} {...file} onClick={((file && file.folder) || (file && !file.folder && onClick)) && fileSelected} />
                    })
                }
            </FilesContainer>
            
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px;
    margin: 8px 16px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    width: auto;
    flex-grow: 1;
    margin: 0;
`;

const TopBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 1px;
`;

const Breadcrumb = styled.div`
    font-family: 'SkyTextRegular';
    text-decoration-line: underline;
    font-size: 16px;
    color: #999999;
    flex: none;
    order: 0;
    flex-shrink: 1;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;


    :hover {
        color: #bbbbbb;
    }
`;

const BreadcrumbContainer = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px;
    gap: 2px;
    width: 100%;
    margin: 0;

    p {
        margin: 0;
        width: 9px;
        font-family: 'SkyTextRegular';
        color: #999999;
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: default;
    }

    ${Breadcrumb}:first-child {
        flex: none;
        flex-grow: 0;
    }

    ${Breadcrumb}:last-child {
        font-family: 'SkyTextMedium';
        color: #999999;
        flex: none;
        flex-grow: 0;
        text-decoration-line: unset;
        cursor: default;
    }
`;

const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 12px 0px;
    flex-grow: 1;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    align-self: stretch;
    overflow-y: auto;
    overflow-x: hidden;
`;

const FileContainer = styled.div`
    align-items: center;
    padding: 3px 12px;
    gap: 8px;
    height: 32px;
    display: inline-flex;
    width: 100%;

    :hover {
        background: #F5F5F5;
    }
`;


const FileIcon = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 4px;
`;

const FileDetails = styled.div`
    width: calc(100% - 56px);
    height: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

    div {
        width: 100%;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div:first-child {
        font-family: 'SkyTextMedium';
        font-size: 14px;
        line-height: 18px;
    }

    div:not(div:first-child) {
        font-family: 'SkyTextRegular';
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.02em;
        
    }
`;

const Loading = styled.div`
    font-family: 'SkyTextMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #0072C9;
    text-align: center;
    margin: auto;
`;

const Error = styled.div`
    font-family: 'SkyTextMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    margin: auto;
    color: #FF6240;

    p {
        color: #666666;
        font-family: 'SkyTextRegular';
        padding: 0px;
        margin-top: 0px;
    }
`;
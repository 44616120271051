import styled from "styled-components"
import modelStore from "../../../lib/stores/store";
import ico_success from "../../../assets/images/success.png";
import ico_error from "../../../assets/images/ico_error.png";
import ico_upload from "../../../assets/images/ico_upload.svg";
import { ButtonAlert } from "../molecules/buttons/ButtonAlert";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import { ModalSave } from "./ModalSave";
import { ObjectMetadata } from "./ObjectMetadata";
import { Providers } from "@microsoft/mgt-element";
import { useEffect, useState } from "react";
import { generateFlatSelection } from "../../../utils/exportUtils";
import {Checkbox} from "./Checkbox";
import { Select } from "../molecules/select/select";
import { getSpecs } from "../../../utils/uploadUtils";

export const ModelDetails = ({setUploadType, selection, selectedObjects, setSelectedObjects}) => {

    const {graphId, model, name} = modelStore();
    const [exportAllowed, setExportAllowed] = useState(null);
    const [showDeeplink, setShowDeeplink] = useState(false);
    const [specs, setSpecs] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);

    useEffect(() => {
      getSpecs().then((res) => {
        if (res) {
          setSpecs(res);
          setSelectedDevice(Object.keys(res)[0]);
        }
      })
    }, []);

    useEffect(() => {
    if (!Object.values(selection).includes(true)) {
        // If nothing selected, the whole model is the selection
        setSelectedObjects(model);
    } else {
        setSelectedObjects(generateFlatSelection(model, selection));
    }
    }, [selection]);
    
    useEffect(() => {
    if (Object.keys(selection).length === 0) {
        setShowDeeplink(graphId && name.includes(".glb"));
    }
    }, [model, graphId, selection, name]);

    return (
        <Container>
            <Content>

                {showDeeplink && 
                <ModalSave
                    text={"Axis Player Deeplink"}
                    style={{padding: "12px"}}
                    secondaryText={`axisPlayer://itemId?${graphId}`}
                    iconStyle={{ height: "20px", marginLeft: "auto" }}
                    theme={"blue"}
                />}

                <Tooltip>
                  {!graphId ? "Export the full model, or s" : "S"}elect meshes to test and export individually, or as a group.
                </Tooltip>

                <ButtonContainer style={{padding: "12px 0px"}}>
                    <ButtonPrimary
                    text={"Export selection"}
                    style={{ flexGrow: 1 }}
                    icon={ico_upload}
                    iconStyle={{ height: "20px", marginRight: "auto" }}
                    theme={((graphId && Object.values(selection).includes(true)) || (!graphId)) && exportAllowed ? "blue" : "red"}
                    onClick={(((graphId &&  Object.values(selection).includes(true)) || (!graphId)) && exportAllowed) ? ()=>{setUploadType("export")} : undefined}
                    />
                </ButtonContainer>

                {!graphId && <ButtonContainer  style={{padding: "0px 0px 12px 0px"}}>
                    <ButtonPrimary
                        text={"Save original file"}
                        style={{ flexGrow: 1}}
                        icon={ico_upload}
                        iconStyle={{ height: "20px", marginRight: "auto" }}
                        theme={"white"}
                        onClick={() => {
                            setUploadType("archive");
                        }}
                    />
                    </ButtonContainer>}

                <Head>
                    <Title theme={exportAllowed ? "green" : "red"}>
                    {exportAllowed ? "Success!" : "Warning!"}
                    </Title>
                </Head>

                <ButtonAlert
                    text={exportAllowed ? "Your model meets Entertainment OS standards for " + selectedDevice :
                    graphId ? "Some elements do not match "+selectedDevice+"'s criteria. Please amend the model in your 3D editor and try again." :
                    "Some elements do not match "+selectedDevice+"'s criteria. You can still save the original file, or amend the model in your 3D editor and try again."
                    }
                    style={{ width: "100%", padding: "12px" }}
                    icon={!exportAllowed ? ico_error : ico_success}
                    iconStyle={{ height: "20px", marginLeft: "auto", paddingLeft: 0 }}
                    theme={!exportAllowed ? "red" : "green"}
                />

                <ObjectMetadata object={selectedObjects} setExportAllowed={setExportAllowed} specs={specs} setSelectedDevice={setSelectedDevice} selectedDevice={selectedDevice} />
            </Content>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 1px;
  height: 100%;
  min-width: 320px;
  width: 320px;
  flex-grow: 0;
  background: #fcfcfc;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  overflow-y: auto;
  overflow-x: hidden;
`;

const Tooltip = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-family: 'SkyTextRegular';
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  gap: 12px;
  flex-shrink: 1;
  align-self: stretch;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 0px 16px 0px;
  background: #fcfcfc;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-shrink: 1;
`;
const Title = styled.h2`
  font-family: "SkyTextRegular";
  font-size: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  flex: none;
  order: 0;
  align-self: left;
  flex-grow: 0;
  color: ${(props) => {
    switch (props.theme) {
      case "green":
        return "#41C90A";
      case "red":
        return "#FF6240";
      case "blue":
        return "#0072C9";
      default:
        return "unset";
    }
  }};
`;

import { Environment, GizmoHelper, GizmoViewport, Grid, OrbitControls, Select, useAnimations, useProgress } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Stage } from "./stage";
import { AnimationBar } from "./ui/organisms/AnimationBar";
import styled from "styled-components";
import { Box3, BufferGeometry, Mesh, Object3D } from "three";
import { getObjectBounds } from "../utils/geometryUtils";
import { MeshBVHVisualizer, computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from "three-mesh-bvh";
import environmentMap from "../assets/images/city.hdr"

const Loader = ({setter}) => {
  const {progress} = useProgress()
  useEffect(() => {
    setter(progress);
  }, [progress, setter])
  return <></>
}

export const Viewer = ({model, setProgress, exportStatus, selected, setSelected, hierarchyWidth}) => {
    const [animator, setAnimator] = useState(null);
    const [activeAnimation, setActiveAnimaton] = useState(null); 
    const [animationPlaying, setAnimationPlaying] = useState(false);
    const [animationsDisabled, setAnimationsDisabled] = useState(false);
    const [size, setSize] = useState(1);
    const [selectionBox, setSelectionBox] = useState(null);

    const [selectionMade, setSelectionMade] = useState(null);

    BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    Mesh.prototype.raycast = acceleratedRaycast;

    useEffect(() => {
      if (model instanceof Object3D) {
        model.traverse(function(node) {
          if (node instanceof Mesh) {
            node.geometry.computeBoundsTree();
          }
          node.frustumCulled = false;
        });
      }
    }, [model]);

    useEffect(() => {
      if (!Object.values(selected).includes(true)) {
        setSelectionBox(null);
      } else {
        var box = new Box3();
        var boxFoundFlag = false;
        Object.keys(selected).forEach((v) => {
          if (selected[v]) {
            var selectedObj = model.getObjectByProperty('uuid', v);
            if (selectedObj.geometry) {
              boxFoundFlag = true;
              box.union(getObjectBounds(selectedObj));
            }
          }
        });
        setSelectionBox(boxFoundFlag ? box : null);
      }
    }, [selected]);

    useEffect(() => {
      if (animator) {
        if (exportStatus === "started") {
          animator.mixer.stopAllAction();
          setAnimationsDisabled(true);
        } else if (exportStatus === "finished") {
          setAnimationsDisabled(false);
          if (activeAnimation && animationPlaying) activeAnimation.play();
        }
      }
    }, [exportStatus, animator, activeAnimation, animationPlaying]);
  
    const selectAnimation = (name) => {
      animator.mixer.stopAllAction();
      if (name === "No Animation") {
        setActiveAnimaton(null);
        setAnimationPlaying(false);
      } else {
        animator.actions[name].reset();
        animator.actions[name].play();
        setActiveAnimaton(animator.actions[name]);
        setAnimationPlaying(true);
      }
    }
  
    const togglePlay = () => {
      if (activeAnimation) {
        setAnimationPlaying(!animationPlaying);
        activeAnimation.paused = animationPlaying;
      }
    }

    const selectObject = (e) => {
      if (!selectionMade) setSelectionMade(true);
      var newSelected = {...selected};
      Object.keys(newSelected).forEach(v => newSelected[v] = false);
      e.forEach(v => newSelected[v.uuid] = true);
      setSelected(newSelected);
    }

    return (
        <Scene hierarchyWidth={hierarchyWidth}>
          {animator && animator.names.length > 0 && 
            <AnimationBar
              disabled={animationsDisabled}
              isPlaying={animationPlaying}
              animations={animator.names} 
              onChange={(v) => selectAnimation(v)} 
              togglePlay={() => togglePlay()} 
            />
           }
          <Canvas camera={{fov: 70, position: [10,10,10]}}>
            <Loader setter={setProgress} />
            <color attach="background" args={["#333333"]} />
            <Grid infiniteGrid={true} cellSize={1} sectionSize={5} sectionColor={"white"} frustumCulled={false} followCamera={false} fadeDistance={size * 10} />
            <Animator setAnimator={setAnimator} animations={model.animations} />
            <Stage adjustCamera={selectionMade ? false : activeAnimation ? false : 1.2} shadows={false} center={null} setSize={setSize} environment={null}>
            <Environment files={environmentMap} />
            <Select multiple onChange={selectObject}>
              <primitive object={model} ref={animator && animator.ref} />
            </Select>
            </Stage>
            {selectionBox && <box3Helper args={[selectionBox]} />}
            <OrbitControls makeDefault />
            <GizmoHelper alignment="bottom-right" margin={[90, 100]}>
              <GizmoViewport axisColors={['#ef869c', '#cbee4b', '#87c3fa']} labelColor="black" />
            </GizmoHelper>
          </Canvas>
        </Scene>
    );
}

const Animator = (props) => {
    props.setAnimator(useAnimations(props.animations));
}

const Scene = styled.div`
  z-index: 0;
  min-width: ${(props) => Math.max(400, 1000-320-props.hierarchyWidth)}px;
  width: calc(100vw - 320px - ${(props) => props.hierarchyWidth}px);
  position: relative;
  // background: linear-gradient(
  //     180deg,
  //     rgba(249, 249, 249, 0) 39.65%,
  //     rgba(171, 171, 171, 0.2) 63.28%
  //   ),
  //   radial-gradient(56.64% 56.64% at 50% 50%, #e3e3e3 0%, #ffffff 100%);
`;
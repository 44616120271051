import create from "zustand";
export const modelStore = create((set) => ({
  masterFile: null,
  masterModel: null,
  model: null,
  name: "",
  graphId: null,
  uploadingStatus: null,
  uploadingProgress: "",
  templateId: null,
  setTemplateIdStore: (templateId) => 
  set((state) => ({
    ...state,
    templateId
  })),
  setMasterFileStore: (masterFile) => 
  set((state) => ({
    ...state,
    masterFile
  })),
  setGraphIdStore: (graphId) =>
  set((state) => ({
    ...state,
    graphId
  })),
  setModelNameStore: (name) =>
  set((state) => ({
    ...state,
    name
  })),
  setMasterModelStore: (masterModel) =>
    set((state) => ({
      ...state,
      masterModel,
    })),
  setModelStore: (model) =>
    set((state) => ({
      ...state,
      model,
    })),
  setUploadingStore: (uploadingStatus) =>
    set((state) => ({
      ...state,
      uploadingStatus,
    })),
  setUploadingProgressStore: (uploadingProgress) =>
    set((state) => ({
      ...state,
      uploadingProgress,
    })),
}));
export default modelStore;

import { Navigate } from "react-router-dom";
import { ButtonPrimary } from "../components/ui/molecules/buttons/ButtonPrimary";
import styled from "styled-components";
import { Providers } from "@microsoft/mgt-react";

export const LoginPage = ({signedIn}) => {

    if (signedIn) {
        return <Navigate to={"/"} />;
    }

    return (
        <Container>
            <ContainerTop>
                <Logo url="assets/images/sky_logo.png"></Logo>
            </ContainerTop>
            <ContainerBottom>
                <ContainerLeft>
                    <Card>
                        <Title>Welcome to Axis</Title>
                        <Subtitle>Entertainment OS 3D assets inspector, uploader, <br /> and scene editor</Subtitle>
                        <ButtonPrimary
                            text={"Login to Axis"}
                            style={{ padding: "10px 18px", fontFamily: "SkyTextRegular", cursor: "pointer" }}
                            theme={"blue"}
                            onClick={() => {Providers.globalProvider.login()}}
                        ></ButtonPrimary>
                    </Card>
                    <div style={{flexGrow: 2}} />
                </ContainerLeft>
                <ContainerRight url="assets/images/login_background.png" />
            </ContainerBottom>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContainerTop = styled.div`
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background: #ffffff;
  z-index: 2;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.05);
  min-width: 750px;
`;

const Logo = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 0px 5px;
  border-right: 1px solid #f5f5f5;
  background: url(${(props) => props.url});
  background-position: 50% 55%;
  background-repeat: no-repeat;
  background-size: 43.75px;
`;

const ContainerBottom = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: calc(100vh - 56px);
`;

const ContainerLeft = styled.div`
    flex-shrink: 1;
    flex-flow: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f5f5;
    display: flex;
`;

const ContainerRight = styled.div`
  flex-grow: 1;
  background: url(${(props) => props.url}) no-repeat;
  background-size: cover;
`;

const Card = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 10vw 0 5vw;
`;

const Title = styled.div`
    font-family: 'SkyTextRegular';
    font-style: normal;
    font-weight: 300;
    font-size: 46px;
    line-height: 50px;
    /* identical to box height, or 109% */
    align-items: center;
    letter-spacing: -0.03em;

    /* Gradients/sky_rainbow_gradient */
    background: linear-gradient(90deg, #FF9421 0%, #F2029C 27.08%, #C400D9 51.04%, #8A02F5 76.56%, #235BFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

const Subtitle = styled.div`
    font-family: 'SkyTextRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */
    align-items: center;
    letter-spacing: -0.01em;

    /* Dark/Black_100 */
    color: #333333;
    padding: 5px 0 40px 0;
`;
import styled from "styled-components";
import { MetadataTable } from "../molecules/tables/MetadataTable";

import ico_success from "../../../assets/images/success.png";
import ico_warning from "../../../assets/images/ico_warning.png";
import { useEffect, useState } from "react";
import { GenerateMetadata } from "../../../utils/GenerateMetadata";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import ico_down_arrow from "../../../assets/images/ico_down_arrow.svg";

export const ObjectMetadata = ({object, setExportAllowed, specs, setSelectedDevice, selectedDevice}) => {
    const [metadata, setMetadata] = useState(null); 

    useEffect(() => {
        if (metadata && specs) {
            if (metadata.trianglesCount > specs[selectedDevice].trianglesCount ||
                metadata.verticesCount > specs[selectedDevice].verticesCount ||
                metadata.textureCount > specs[selectedDevice].textureCount ||
                metadata.materialsCount > specs[selectedDevice].materialsCount ||
                metadata.animationsCount > specs[selectedDevice].animationsCount ||
                metadata.callsCount > specs[selectedDevice].callsCount) 
            {
                if (setExportAllowed) setExportAllowed(false);
            } else {
                if (setExportAllowed) setExportAllowed(true);
            }
        }
    }, [metadata, specs, selectedDevice, setExportAllowed]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleValueChange = (value) => {
        setSelectedDevice(value);
    };
    const handleChange = (value) => {
        handleValueChange(value);
        handleClose();
    };

    return(
        <Container>

            <SelectContainer>
                <p>Select device</p>
                <ButtonPrimary
                    onClick={handleOpen}
                    text={selectedDevice ? selectedDevice.length > 15 ? selectedDevice.substring(0, 15).concat("...") : selectedDevice : "Loading..."}
                    leftAlign={true}
                    style={{border: "1px solid #cccccc", borderRadius: "4px", fontFamily: "SkyTextRegular", width: "100%"}}
                    icon={ico_down_arrow}
                    iconStyle={{ height: "7px" }}
                    theme={"white"}
                />
                <div style={{position: "relative"}}>
                <DropdownStyle isVisible={open}>
                    {specs && Object.keys(specs).map((value, index) => (
                        <DropdownItem
                            onClick={() => handleChange(value)}
                            active={value === selectedDevice}
                            key={index}>
                            {value}
                        </DropdownItem>
                    ))}
                </DropdownStyle>
                </div>
            </SelectContainer>


            <GenerateMetadata object={object} setMetadata={setMetadata} />
            {metadata ?
            <>
                <Grid>
                    <ColumnA>Property</ColumnA>
                    <ColumnB>Value</ColumnB>
                    <Spacer>|</Spacer>
                    <ColumnC>Max Value</ColumnC>
                </Grid>
                    <MetadataTable
                    property={"Polygons"}
                    count={metadata.trianglesCount}
                    maxValue={specs && specs[selectedDevice] ? parseInt(specs[selectedDevice].trianglesCount).toLocaleString("en-US") : "..."}
                    icon={(specs && specs[selectedDevice] && metadata) && metadata.trianglesCount < specs[selectedDevice].trianglesCount ? ico_success : ico_warning}
                />
                <MetadataTable
                    property={"Vertices"}
                    count={metadata.verticesCount}
                    maxValue={specs && specs[selectedDevice] ? parseInt(specs[selectedDevice].verticesCount).toLocaleString("en-US") : "..."}
                    icon={(specs && specs[selectedDevice] && metadata) && metadata.verticesCount < specs[selectedDevice].verticesCount ? ico_success : ico_warning}
                />
                <MetadataTable
                    property={"Textures"}
                    count={metadata.textureCount}
                    maxValue={specs && specs[selectedDevice] ? specs[selectedDevice].textureCount : "..."}
                    icon={(specs && specs[selectedDevice] && metadata) && metadata.textureCount < specs[selectedDevice].textureCount ? ico_success : ico_warning}
                />
                <MetadataTable
                    property={"Materials"}
                    count={metadata.materialsCount}
                    maxValue={specs && specs[selectedDevice] ? specs[selectedDevice].materialsCount : "..."}
                    icon={(specs && specs[selectedDevice] && metadata) && metadata.materialsCount < specs[selectedDevice].materialsCount ? ico_success : ico_warning}
                />
                <MetadataTable
                    property={"Animations"}
                    count={metadata.animationsCount}
                    maxValue={specs && specs[selectedDevice] ? specs[selectedDevice].animationsCount : "..."}
                    icon={(specs && specs[selectedDevice] && metadata) && metadata.animationsCount < specs[selectedDevice].animationsCount ? ico_success : ico_warning}
                />
                <MetadataTable
                    property={"Draw Calls"}
                    count={metadata.callsCount}
                    maxValue={specs && specs[selectedDevice] ? specs[selectedDevice].callsCount : "..."}
                    icon={(specs && specs[selectedDevice] && metadata) && metadata.callsCount < specs[selectedDevice].callsCount ? ico_success : ico_warning}
                />
            </> :
            <p>Loading...</p>}
        </Container>
    );
}

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'SkyTextRegular';
    padding: 0 0 16px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 16px;
    > p {
        font-size: 12px;
        line-height: 18px;
        color: #333333;
        padding: 0px 0px 6px 0px;
        margin: 0;
    }
`;

const DropdownStyle = styled.div`
    position: absolute;
    width: 100%;
    background: #FCFCFC;
    border: 1px solid #cccccc;
    border-top: 0px;
    border-radius: 0px 0px 4px 4px;
    box-sizing: border-box;
    margin-top: -3px;
    ${(p) =>
    p.isVisible !== true &&
    `
    visibility: hidden;
    `}
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
    cursor: pointer;
    ${(p) =>
    p.active &&
    `
    color: #166edc;
    font-weight: 500;
    `}
    &:hover, :focus, :focus:hover {
    background-color: #166edc;
    color: #fafafa;
    outline: none;
    }
`;

const Container = styled.div`
width: 100%;
`;

const Grid = styled.div`
  font-size: 12px;
  display: flex;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
//   padding-top: 10px;
  grid-template-rows: auto;
  background: #fcfcfc;
`;

const ColumnA = styled.div`
  font-family: "SkyTextMedium";
  align-items: left;
  // width: 120px;
  flex-grow: 1;
  padding-bottom: 6px;
  border-bottom: 1px solid #e1e1e1;
`;

const ColumnB = styled.div`
  font-family: "SkyTextMedium";
  text-align: right;
  width: 70px;
  border-bottom: 1px solid #e1e1e1;
`;

const Spacer = styled.div`
  font-family: "SkyTextRegular";
  text-align: center;
  color: #d1d1d1;
  // flex-grow: 1;
  // width: 30px;
  padding: 0px 6px 0px 12px;
  border-bottom: 1px solid #e1e1e1;
`;
const ColumnC = styled.div`
  font-family: "SkyTextMedium";
  text-align: center;
  width: 70px;
  // flex-grow: 1;
  border-bottom: 1px solid #e1e1e1;
`;

import styled from "styled-components";
import ico_upload from "../../../assets/images/ico_upload.svg";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import { ModalSave } from "./ModalSave";
import { useState } from "react";
import modelStore from "../../../lib/stores/store";
import { useEffect } from "react";
import { ObjectMetadata } from "./ObjectMetadata";
import { getSpecs } from "../../../utils/uploadUtils";
import ico_play from "../../../assets/images/ico_play.svg";
import { ButtonAlert } from "../molecules/buttons/ButtonAlert";
import ico_error from "../../../assets/images/ico_error.png";

export const TemplateDetails = ({setUploadType, allObjects, onPreviewTemplate}) => {
  const { templateId } = modelStore();
  const [specs, setSpecs] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [previewAvailable, setPreviewAvailable] = useState(false);
  const [sceneWarning, setSceneWarning] = useState(false);

  useEffect(() => {
    getSpecs().then((res) => {
        if (res) {
          setSpecs(res);
          setSelectedDevice(Object.keys(res)[0]);
        }
    })
  }, []);

  useEffect(() => {
    setPreviewAvailable(allObjects.filter((object) => object.type === "PerspectiveCamera").length > 0);
  }, [allObjects]);

  return (
    <Container>
      <Content>

          {templateId && 
          <ModalSave
            text={"Scene Deeplink"}
            secondaryText={`axisPlayer://templateId?${templateId}`}
            style={{padding: "12px"}}
            iconStyle={{ height: "20px", marginLeft: "auto" }}
            theme={"blue"}
          />}

          <ButtonContainer style={{padding: "12px 0px"}}>
            <ButtonPrimary
              style={{ flexGrow: 1 }}
              text={"Preview scene"}
              icon={ico_play}
              theme={previewAvailable ? "white" : "red"}
              iconStyle={{ height: "16px", marginRight: "auto" }}
              onClick={previewAvailable ? onPreviewTemplate : undefined}
            />
          </ButtonContainer>

          <ButtonContainer style={{padding: "0px 0px 12px 0px"}}>
            <ButtonPrimary
              style={{ flexGrow: 1 }}
              text={"Upload scene"}
              icon={ico_upload}
              iconStyle={{ height: "20px", marginRight: "auto" }}
              theme={previewAvailable && !sceneWarning ? "blue" : "red"}
              onClick={previewAvailable && !sceneWarning ? () => {setUploadType("template")} : undefined}
            />
          </ButtonContainer>

            {sceneWarning &&
            <>
             <Head>
                <Title theme={"red"}>Warning!</Title>
              </Head>

                <ButtonAlert
                    text={"Some elements of this scene do not match "+selectedDevice+"'s criteria, and may not perform as expected on the target hardware."}
                    style={{ width: "100%", padding: "12px" }}
                    icon={ico_error}
                    iconStyle={{ height: "20px", marginLeft: "auto", paddingLeft: 0 }}
                    theme={"red"}
                />
            </>}
               
         

          <ObjectMetadata object={allObjects} setExportAllowed={(v)=>setSceneWarning(!v)}  specs={specs} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} />
      </Content>
    </Container>
  );
};
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
gap: 1px;
height: 100%;
min-width: 320px;
width: 320px;
flex-grow: 0;
background: #fcfcfc;
box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
overflow-y: auto;
overflow-x: hidden;
`;

const Content = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 24px 16px;
gap: 12px;
flex-shrink: 1;
align-self: stretch;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 0px 16px 0px;
  background: #fcfcfc;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-shrink: 1;
`;
const Title = styled.h2`
  font-family: "SkyTextRegular";
  font-size: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  flex: none;
  order: 0;
  align-self: left;
  flex-grow: 0;
  color: ${(props) => {
    switch (props.theme) {
      case "green":
        return "#41C90A";
      case "red":
        return "#FF6240";
      case "blue":
        return "#0072C9";
      default:
        return "unset";
    }
  }};
`;

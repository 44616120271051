import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ico_mesh from "../../../assets/images/ico_mesh.svg";
import ico_camera from "../../../assets/images/ico_camera.svg";
import ico_light from "../../../assets/images/ico_light.svg";
import { AmbientLight, Color, Object3D, PerspectiveCamera } from "three";
import { FileBrowser } from "./FileBrowser";
import { Loader } from "../../../utils/loader";
import { optimiseModel } from "../../../utils/modelOptimisation";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import { Light } from "three";
import { getDownloadUrl, getEnvironment } from "../../../utils/uploadUtils";

export const TemplateHierarchy = ({selected, setSelected, allObjects, setAllObjects, hierarchyWidth, setDragging, existingJSON, setLoadingTemplate, setEnvironment, disableHotkeys}) => {
    const contentRef = useRef();

    const [loadingModel, setLoadingModel] = useState(false);
    const [errors, setErrors] = useState([]);
    const [currentTab, setCurrentTab] = useState("Scene");

    const addError = (e) => {
        setLoadingModel(false);
        setErrors([...errors, e]);
    }

    const AddLoadedModel = (model, id = null) => {
        model = optimiseModel(model);
        model.graphId = id;
        if (existingJSON) {
            var modelParams = existingJSON.models.filter((v) => v.id === id);
            console.log(modelParams);
        }
        setAllObjects([...allObjects, model]);
        setLoadingModel(false);
    }

    const AddModel = (file) => {
        console.log(file);
        if (!loadingModel) {
            setLoadingModel(true);
            loader.loadFileFromURL(file["@microsoft.graph.downloadUrl"], file["name"], file["id"]);
        }
    }

    const loader = new Loader({setScene: AddLoadedModel, setError: addError});

    const AddLight = (color = null, intensity = null) => {
        var light = new Object3D();
        light.lightType = "point";
        light.name = `Light_${allObjects.filter(v => {return(v.lightType)}).length + 1}`;
        if (color) light.color = color;
        if (intensity) light.intensity = intensity;
        setAllObjects([...allObjects, light]);
    }

    const AddCamera = (position = null, quaternion = null, scale = null, fov = null) => {
        var newCamera = new PerspectiveCamera(fov ? fov : 70, 16/9);
        newCamera.name = `Camera_${allObjects.filter(v => {return(v instanceof PerspectiveCamera)}).length + 1}`;
        newCamera.cameraType = "fixed";
        newCamera.title = "";
        newCamera.synopsis = "";
        if (position) newCamera.position.set(position.x, position.y, position.z);
        if (quaternion) newCamera.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
        if (scale) newCamera.scale.set(scale.x, scale.y, scale.z);
        setAllObjects([...allObjects, newCamera]);
    }

    useEffect(() => {
        if (existingJSON) {
            setLoadingTemplate(true);

            var objects = [];
            var environmentLoaded = false;

            const checkLoadComplete = () => {
                if (objects.length === (existingJSON.models.length + existingJSON.cameras.length + existingJSON.lights.length) && environmentLoaded) {    
                    setAllObjects(objects);
                    setTimeout(() => {
                        setLoadingTemplate(false);
                    }, 3000);
                }
            }

            const modelLoader = new Loader({setScene: (model,id) => {
                model = optimiseModel(model);

                var modelParams = existingJSON.models[id];

                model.graphId = modelParams.id;
                model.position.set(modelParams.position.x, modelParams.position.y, modelParams.position.z); 
                model.quaternion.set(modelParams.quaternion._x, modelParams.quaternion._y, modelParams.quaternion._z, modelParams.quaternion._w);
                model.scale.set(modelParams.scale.x, modelParams.scale.y, modelParams.scale.z);
                
                objects.push(model);
                checkLoadComplete();
            }, setError: addError});

            for (const model of existingJSON.models) {
                modelLoader.loadFileFromURL(model.url, model.name, existingJSON.models.indexOf(model));
            }

            if (existingJSON.environment) {
                getEnvironment(existingJSON.environment.id).then((res) => {
                    setEnvironment({...res, rotation: existingJSON.environment.rotation, castShadows: existingJSON.environment.castShadows});
                    environmentLoaded = true;
                    checkLoadComplete();
                });
            } else {
                environmentLoaded = true;
            }

            for (const camera of existingJSON.cameras) {

                var newCamera = new PerspectiveCamera(camera.fov, 16/9);
                newCamera.name = `Camera_${camera.id}`;
                newCamera.position.set(camera.position.x, camera.position.y, camera.position.z);
                if (camera.threeQuaternion) newCamera.quaternion.set(camera.threeQuaternion._x, camera.threeQuaternion._y, camera.threeQuaternion._z, camera.threeQuaternion._w);
                else newCamera.quaternion.set(camera.quaternion._x, camera.quaternion._y, camera.quaternion._z, camera.quaternion._w);
                newCamera.scale.set(camera.scale.x, camera.scale.y, camera.scale.z);
                newCamera.cameraType = camera.type;
                if (newCamera.cameraType === "orbit") {
                    newCamera.orbitRadius = camera.orbitRadius;
                    newCamera.orbitHeight = camera.orbitHeight;
                }
                newCamera.title = camera.title;
                newCamera.synopsis = camera.synopsis;
                
                objects.push(newCamera);
                checkLoadComplete();
            }

            for (const light of existingJSON.lights) {
                var newLight = new Object3D();
                newLight.position.set(light.position.x, light.position.y, light.position.z);
                newLight.quaternion.set(light.quaternion._x, light.quaternion._y, light.quaternion._z, light.quaternion._w);
                newLight.scale.set(light.scale.x, light.scale.y, light.scale.z);
                newLight.lightType = light.type;
                newLight.name = `Light_${light.id}`;
                newLight.color = light.color;
                newLight.intensity = light.intensity;
                newLight.distance = light.distance;
                newLight.penumbra = light.penumbra;
                newLight.angle = light.angle;
                objects.push(newLight);
                checkLoadComplete();
            }
        }
    }, [existingJSON]);

    const onSelectionChanged = (uuid, e, double = false) => {
        if (selected[uuid] === true) return;
        var newSelected = {...selected};
        Object.keys(newSelected).forEach(v => newSelected[v] = false);
        newSelected[uuid] = true;
        setSelected(newSelected);
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (Object.values(selected).includes(true)) {
                if (event.key === "x" && !disableHotkeys) {
                    setSelected({});
                    setAllObjects(allObjects.filter(v => {return(!selected[v.uuid] || selected[v.uuid] === false)}));
                }
            }
        }

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    },[selected, setAllObjects, allObjects, setSelected, disableHotkeys]);

    return (
        <Container width={hierarchyWidth}>
            <WidthDrag onMouseDown={(e) => {
                setDragging(true); 
            }} />
            <Tabs tabs={["Scene","Add"]} setCurrentTab={setCurrentTab} currentTab={currentTab} />
            {currentTab === "Scene" && 
                (allObjects && allObjects.length === 0 ?
                <SceneHelpText>Your template currently has no objects. Use the Add tab to bring models, cameras, and lights into your scene.</SceneHelpText>
                :
                <TemplateContent ref={contentRef}>
                    {allObjects && allObjects.map(child => <Node key={child.uuid} node={child} tabIndex={1} uuid={child.uuid} selected={selected} onSelectionChanged={onSelectionChanged} />)}
                </TemplateContent>)
            }
            {currentTab === "Add" &&
                <AddObjects loadingModel={loadingModel}>
                    {loadingModel && <LoadingSpinner />}
                    <ButtonContainer>
                        <ButtonPrimary
                        text={"Add Camera"}
                        style={{ cursor: "pointer", width: "auto" }}
                        icon={ico_camera}
                        iconStyle={{ height: "20px", marginRight: "auto", filter: 'unset' }}
                        theme={"blue"}
                        onClick={() => {AddCamera()}}
                        />
                        <ButtonPrimary
                        text={"Add Light"}
                        style={{ cursor: "pointer", width: "auto" }}
                        icon={ico_light}
                        iconStyle={{ height: "20px", marginRight: "auto", filter: 'unset' }}
                        theme={"blue"}
                        onClick={() => {AddLight()}}
                        />
                    </ButtonContainer>
                    <FileBrowser 
                        onClick={AddModel}
                        baseDir={"Assets"}
                        siteId={
                        "97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1"
                        }
                        style={{background: 'unset', border: 'unset', paddingTop: '0', height: '1px'}}
                        thumbnails
                    />
                </AddObjects>
            }
        </Container>
    );
}

const Node = ({node, tabIndex, uuid, selected, onSelectionChanged}) => {
    return (
        <>
            <NodeContainer className="NodeContainer" id={uuid} style={{paddingLeft : `${tabIndex * 10}px`}} onClick={(e) => onSelectionChanged(uuid, e)} selected={selected && selected[uuid]} onDoubleClick={(e) => onSelectionChanged(uuid, e, true)}>
                <NodeIcon src={node instanceof PerspectiveCamera ? ico_camera : node.lightType ? ico_light : ico_mesh} strokeIcon={node instanceof PerspectiveCamera || node.lightType} />
                <Label>{node.name}</Label>
            </NodeContainer>
        </>
    );
}

const Tabs = ({tabs, setCurrentTab, currentTab}) => {
    return(
        <TabsContainer>
            {tabs.map((name, index) => <Tab onClick={() => setCurrentTab(name)} active={currentTab === name} key={index}>{name}</Tab>)}
        </TabsContainer>
    );
}

const WidthDrag = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    width: 10px;
    cursor: col-resize;
    z-index: 15;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;

    flex-basis: ${(props) => props.width}px;
    flex-grow: 0;
    height: 100%;
    background: #fcfcfc;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
    overflow-x: hidden;
    overflow-y: hidden;
    user-select: none;
    justify-content: space-between;
`;

const AddObjects = styled.div`
    display: flex;
    overflow-y: hidden;
    flex-shrink: 1;
    flex-grow: 1;

    flex-direction: column;
    padding: 20px 0 0 0;
    gap: 0px;
    font-family: 'SkyTextRegular';

    pointer-events: ${(props) => props.loadingModel ? 'none' : 'unset'};
    opacity: ${(props) => props.loadingModel ? '0.7' : 'unset'};
`;

const TemplateContent = styled.div`
    display: flex;
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;

    flex-direction: column;
    padding: 20px 0;
    gap: 0px;
    font-family: 'SkyTextRegular';
`;

const NodeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    padding: 4px 0px 4px 4px;
    gap: 8px;
    height: 28px;

    cursor: pointer;

    background-color: ${(props) => (props.selected) ? '#f5f5f5'  : 'unset'};
    color: ${(props) => (props.selected) ? '#0072c9'  : '#333333'};

    :hover {
        background-color: #f0f0f0;
    }
`;

const NodeIcon = styled.img`
    width: 18px;
    height: 18px;
    flex-grow: 0;
    flex-shrink: 0;
    filter: ${(props) => props.strokeIcon ? 'drop-shadow(1px 1px 0px #777)drop-shadow(-1px -1px 0px #777)' : 'unset'};
`;

const Label = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    font-size: 13px;
    line-height: 18px;
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 12px 12px 0px;
    box-sizing: border-box;
`;

const Tab = styled.div`
    font-family: "SkyTextMedium";
    font-size: 14px;
    color: ${(props) => props.active ? '#0072c9'  : '#cccccc'};
    border-bottom: 1px solid ${(props) => props.active ? '#0072c9'  : '#cccccc'};
    padding: 4px 12px;
    cursor: pointer;
    transition: all .25s;
    flex-grow: 1;
    text-align: center;

    :hover {
        color: #0072c9;
    }
`;

const SceneHelpText = styled.div`
    display: flex;
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    flex-direction: column;
    padding: 18px 12px;
    text-align: center;
    gap: 0px;
    font-family: 'SkyTextRegular';
    font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0px 12px 12px;
  padding: 0 0 12px 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 6px;
  border-bottom: 1px solid #cccccc;
`;
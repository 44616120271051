import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useEffect, useState } from "react";

export function UserDetails() {

  const [signInStatus, setSignInStatus] = useState(ProviderState.Loading);
  const [details, setDetails] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setSignInStatus(provider.state);
      if (provider.state === ProviderState.SignedIn) {
        provider.getAccessToken().then((res) => setAccessToken(res));
        provider.graph.client.api('me').get().then(gotMe => setDetails(gotMe));
      }
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    }
  }, []);

  return [signInStatus, details, accessToken];

}
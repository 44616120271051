import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import ico_close from "../../../assets/images/ico_close_2.svg";


export const FilenameInput = ({setFilename, validFilename, placeholder}) => {
    const inputRef = useRef();
    const [name, setName] = useState(null);

    useEffect(() => {
        if (setFilename) setFilename(name);
    }, [name, setFilename]);

    return (
        <Container>
        <Label>File name</Label>
        <InputContainer>
          <Input ref={inputRef} valid={validFilename} onChange={(v) => setName(v.target.value)} placeholder={placeholder} />
          <Clear src={ico_close} onClick={() => {inputRef.current.value = ""; if (setFilename) setFilename("");}} />
        </InputContainer>
      </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  font-family: "SkyTextRegular";
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  padding: 0 0 6px;
`;

const InputContainer = styled.div`
  width: inherit;
  box-sizing: border-box;
  background: #FFFFFF;
  position: relative;
`;

const Input = styled.input`
  box-sizing: border-box;
  padding: 6px 40px 6px 6px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  font-family: "SkyTextRegular";
  border: 1px solid;
  border-color: ${props => props.valid ? '#cccccc' : '#cc0000'};
  border-radius: 2px;
  text-overflow: ellipsis;

`;

const Clear = styled.img`
  width: 10px;
  height: 10px;
  z-index: 1;
  position: absolute;
  top: calc(50% - 5px);
  right: 20px;
  cursor: pointer;
`;
/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";
import NavTop from "../components/ui/organisms/NavTop";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Providers } from "@microsoft/mgt-element";
import { useEffect } from "react";
import { useState } from "react";

export const PreviewPage = ({signedIn, accessToken}) => {

  const {unityProvider, sendMessage} = useUnityContext({
    loaderUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.loader.js",
    dataUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.data",
    frameworkUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.framework.js",
    codeUrl: "AxisPlayerWeb/Build/AxisPlayerWeb.wasm",
  });

  const [template, setTemplate] = useState(null);

  useEffect(() => {
    setTemplate(
        JSON.stringify({ 
            "name":"camera_system_test_001.json",
            "models":[{"url":"https://skyglobal.sharepoint.com/sites/ProjectAxis15/_layouts/15/download.aspx?UniqueId=4e629263-2b5f-4ecf-b34d-df9d3ef76f92&Translate=false&tempauth=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvc2t5Z2xvYmFsLnNoYXJlcG9pbnQuY29tQDY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2NzcyNTYyODciLCJleHAiOiIxNjc3MjU5ODg3IiwiZW5kcG9pbnR1cmwiOiJuTnU3OUZhWU5FU2JwWjdSNkJrSnR5SEV0MXZHeUhUYWVwZEJ4M3ZyaUpvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQwIiwiaXNsb29wYmFjayI6IlRydWUiLCJjaWQiOiJOVFEzTm1Oa1lqSXROakJsWkMweFpHVm1MVFJpTjJRdFltTmhOalF6TldVd05HWXoiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiT1RjME5URmxaREF0WlRJeVpTMDBNV1JrTFRsbVkyVXRaR1l5TmpFNU1EVmpNRGRqIiwiYXBwX2Rpc3BsYXluYW1lIjoiR3JhcGggRXhwbG9yZXIiLCJnaXZlbl9uYW1lIjoiTWF0dGhldyIsImZhbWlseV9uYW1lIjoiV29vZGluZyIsInNpZ25pbl9zdGF0ZSI6IltcImttc2lcIl0iLCJhcHBpZCI6ImRlOGJjOGI1LWQ5ZjktNDhiMS1hOGFkLWI3NDhkYTcyNTA2NCIsInRpZCI6IjY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsInVwbiI6Im1hdHRoZXcud29vZGluZ0Bza3kudWsiLCJwdWlkIjoiMTAwMzIwMDBERTRDQzdDMCIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfDEwMDMyMDAwZGU0Y2M3YzBAbGl2ZS5jb20iLCJzY3AiOiJhbGxmaWxlcy53cml0ZSBncm91cC53cml0ZSBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIGFsbHByb2ZpbGVzLndyaXRlIiwidHQiOiIyIiwidXNlUGVyc2lzdGVudENvb2tpZSI6bnVsbCwiaXBhZGRyIjoiMjAuMTkwLjE2OS4yNCJ9.MW1RVWQwTTNWQ29teG1kSXNVSmNuWm5QUlliMVh3S3lUSWxkYlVyZHMxRT0&ApiVersion=2.0","position":{"x":5.066262797834691,"y":0.5827874870634702,"z":-2.7407775869587776},"quaternion":{"isQuaternion":true,"_x":0,"_y":0,"_z":0,"_w":1},"scale":{"x":1,"y":1,"z":1},"idleAnimation":null},
            {"url":"https://skyglobal.sharepoint.com/sites/ProjectAxis15/_layouts/15/download.aspx?UniqueId=4e629263-2b5f-4ecf-b34d-df9d3ef76f92&Translate=false&tempauth=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvc2t5Z2xvYmFsLnNoYXJlcG9pbnQuY29tQDY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2NzcyNTYyODciLCJleHAiOiIxNjc3MjU5ODg3IiwiZW5kcG9pbnR1cmwiOiJuTnU3OUZhWU5FU2JwWjdSNkJrSnR5SEV0MXZHeUhUYWVwZEJ4M3ZyaUpvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQwIiwiaXNsb29wYmFjayI6IlRydWUiLCJjaWQiOiJOVFEzTm1Oa1lqSXROakJsWkMweFpHVm1MVFJpTjJRdFltTmhOalF6TldVd05HWXoiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiT1RjME5URmxaREF0WlRJeVpTMDBNV1JrTFRsbVkyVXRaR1l5TmpFNU1EVmpNRGRqIiwiYXBwX2Rpc3BsYXluYW1lIjoiR3JhcGggRXhwbG9yZXIiLCJnaXZlbl9uYW1lIjoiTWF0dGhldyIsImZhbWlseV9uYW1lIjoiV29vZGluZyIsInNpZ25pbl9zdGF0ZSI6IltcImttc2lcIl0iLCJhcHBpZCI6ImRlOGJjOGI1LWQ5ZjktNDhiMS1hOGFkLWI3NDhkYTcyNTA2NCIsInRpZCI6IjY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsInVwbiI6Im1hdHRoZXcud29vZGluZ0Bza3kudWsiLCJwdWlkIjoiMTAwMzIwMDBERTRDQzdDMCIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfDEwMDMyMDAwZGU0Y2M3YzBAbGl2ZS5jb20iLCJzY3AiOiJhbGxmaWxlcy53cml0ZSBncm91cC53cml0ZSBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIGFsbHByb2ZpbGVzLndyaXRlIiwidHQiOiIyIiwidXNlUGVyc2lzdGVudENvb2tpZSI6bnVsbCwiaXBhZGRyIjoiMjAuMTkwLjE2OS4yNCJ9.MW1RVWQwTTNWQ29teG1kSXNVSmNuWm5QUlliMVh3S3lUSWxkYlVyZHMxRT0&ApiVersion=2.0","position":{"x":-3.4470559139863854,"y":0.31141571783761934,"z":3.3783402120468207},"quaternion":{"isQuaternion":true,"_x":0,"_y":0,"_z":0,"_w":1},"scale":{"x":1,"y":1,"z":1},"idleAnimation":null},
            {"url":"https://skyglobal.sharepoint.com/sites/ProjectAxis15/_layouts/15/download.aspx?UniqueId=4e629263-2b5f-4ecf-b34d-df9d3ef76f92&Translate=false&tempauth=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvc2t5Z2xvYmFsLnNoYXJlcG9pbnQuY29tQDY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2NzcyNTYyODciLCJleHAiOiIxNjc3MjU5ODg3IiwiZW5kcG9pbnR1cmwiOiJuTnU3OUZhWU5FU2JwWjdSNkJrSnR5SEV0MXZHeUhUYWVwZEJ4M3ZyaUpvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQwIiwiaXNsb29wYmFjayI6IlRydWUiLCJjaWQiOiJOVFEzTm1Oa1lqSXROakJsWkMweFpHVm1MVFJpTjJRdFltTmhOalF6TldVd05HWXoiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiT1RjME5URmxaREF0WlRJeVpTMDBNV1JrTFRsbVkyVXRaR1l5TmpFNU1EVmpNRGRqIiwiYXBwX2Rpc3BsYXluYW1lIjoiR3JhcGggRXhwbG9yZXIiLCJnaXZlbl9uYW1lIjoiTWF0dGhldyIsImZhbWlseV9uYW1lIjoiV29vZGluZyIsInNpZ25pbl9zdGF0ZSI6IltcImttc2lcIl0iLCJhcHBpZCI6ImRlOGJjOGI1LWQ5ZjktNDhiMS1hOGFkLWI3NDhkYTcyNTA2NCIsInRpZCI6IjY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsInVwbiI6Im1hdHRoZXcud29vZGluZ0Bza3kudWsiLCJwdWlkIjoiMTAwMzIwMDBERTRDQzdDMCIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfDEwMDMyMDAwZGU0Y2M3YzBAbGl2ZS5jb20iLCJzY3AiOiJhbGxmaWxlcy53cml0ZSBncm91cC53cml0ZSBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIGFsbHByb2ZpbGVzLndyaXRlIiwidHQiOiIyIiwidXNlUGVyc2lzdGVudENvb2tpZSI6bnVsbCwiaXBhZGRyIjoiMjAuMTkwLjE2OS4yNCJ9.MW1RVWQwTTNWQ29teG1kSXNVSmNuWm5QUlliMVh3S3lUSWxkYlVyZHMxRT0&ApiVersion=2.0","position":{"x":3.2768085611409896,"y":0.4823208826337413,"z":5.925854647988645},"quaternion":{"isQuaternion":true,"_x":0,"_y":0,"_z":0,"_w":1},"scale":{"x":1,"y":1,"z":1},"idleAnimation":null},
            {"url":"https://skyglobal.sharepoint.com/sites/ProjectAxis15/_layouts/15/download.aspx?UniqueId=4e629263-2b5f-4ecf-b34d-df9d3ef76f92&Translate=false&tempauth=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvc2t5Z2xvYmFsLnNoYXJlcG9pbnQuY29tQDY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2NzcyNTYyODciLCJleHAiOiIxNjc3MjU5ODg3IiwiZW5kcG9pbnR1cmwiOiJuTnU3OUZhWU5FU2JwWjdSNkJrSnR5SEV0MXZHeUhUYWVwZEJ4M3ZyaUpvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQwIiwiaXNsb29wYmFjayI6IlRydWUiLCJjaWQiOiJOVFEzTm1Oa1lqSXROakJsWkMweFpHVm1MVFJpTjJRdFltTmhOalF6TldVd05HWXoiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiT1RjME5URmxaREF0WlRJeVpTMDBNV1JrTFRsbVkyVXRaR1l5TmpFNU1EVmpNRGRqIiwiYXBwX2Rpc3BsYXluYW1lIjoiR3JhcGggRXhwbG9yZXIiLCJnaXZlbl9uYW1lIjoiTWF0dGhldyIsImZhbWlseV9uYW1lIjoiV29vZGluZyIsInNpZ25pbl9zdGF0ZSI6IltcImttc2lcIl0iLCJhcHBpZCI6ImRlOGJjOGI1LWQ5ZjktNDhiMS1hOGFkLWI3NDhkYTcyNTA2NCIsInRpZCI6IjY4Yjg2NWQ1LWNmMTgtNGIyYi04MmE0LWE0ZWRkYjljNTIzNyIsInVwbiI6Im1hdHRoZXcud29vZGluZ0Bza3kudWsiLCJwdWlkIjoiMTAwMzIwMDBERTRDQzdDMCIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfDEwMDMyMDAwZGU0Y2M3YzBAbGl2ZS5jb20iLCJzY3AiOiJhbGxmaWxlcy53cml0ZSBncm91cC53cml0ZSBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIGFsbHByb2ZpbGVzLndyaXRlIiwidHQiOiIyIiwidXNlUGVyc2lzdGVudENvb2tpZSI6bnVsbCwiaXBhZGRyIjoiMjAuMTkwLjE2OS4yNCJ9.MW1RVWQwTTNWQ29teG1kSXNVSmNuWm5QUlliMVh3S3lUSWxkYlVyZHMxRT0&ApiVersion=2.0","position":{"x":11.012156744503958,"y":0.46981511626958916,"z":-5.23536831855677},"quaternion":{"isQuaternion":true,"_x":0,"_y":0,"_z":0,"_w":1},"scale":{"x":1,"y":1,"z":1},"idleAnimation":null}],
            "cameras":[{"id":0,"position":{"x":4.732420154101475,"y":0.6625159653506527,"z":-4.8848244467217965},"quaternion":{"isQuaternion":true,"_x":-6.03302913734595e-17,"_y":0.07684300582844641,"_z":4.649709168887672e-18,"_w":0.9970432049090198},"scale":{"x":1,"y":1,"z":1},"fov":70,"focalLength":14.05833194136769,"near":0.1,"far":2000},{"id":1,"position":{"x":-3.5035627787765113,"y":1.053796332341923,"z":1.218926846345937},"quaternion":{"isQuaternion":true,"_x":0.21649693956945312,"_y":0.00905150558300555,"_z":-0.0020073186088125517,"_w":0.9762392719388746},"scale":{"x":1,"y":1,"z":1},"fov":70,"focalLength":14.05833194136769,"near":0.1,"far":2000},{"id":2,"position":{"x":3.162468713232104,"y":1.7883278283039594,"z":4.437648438349793},"quaternion":{"isQuaternion":true,"_x":0.3327843754933884,"_y":0.06971595686396011,"_z":-0.02467867029517214,"_w":0.9400985097416547},"scale":{"x":1,"y":1,"z":1},"fov":70,"focalLength":14.05833194136769,"near":0.1,"far":2000},{"id":3,"position":{"x":9.244363179954455,"y":0.5940845987301691,"z":-6.528658705957414},"quaternion":{"isQuaternion":true,"_x":3.775261529779003e-17,"_y":0.40377772866302447,"_z":-1.6662345366111898e-17,"_w":0.9148571177160557},"scale":{"x":1,"y":1,"z":1},"fov":70,"focalLength":14.05833194136769,"near":0.1,"far":2000}],
            "lights":[{"id":0,"position":{"x":-4.8537173832456615,"y":2.1526438584089647,"z":4.2335721592182525},"quaternion":{"isQuaternion":true,"_x":0,"_y":0,"_z":0,"_w":1},"scale":{"x":1,"y":1,"z":1},"color":16711422,"intensity":1}]
        })
    )
  }, []);

//   useEffect(() => {
//     sendMessage("PreviewLoader", "ReceiveAccessToken", accessToken);
//   }, [accessToken, sendMessage]);

useEffect(() => {
    if (template) sendMessage("Home", "LoadJSONPreview", template);
}, [accessToken, sendMessage, template]);

  if (!signedIn) {
    return <Navigate to={"/login"} />;
  }

  if (!accessToken) {
    return <Navigate to={"/"} />;
  }


  return (
    <Container>
        <NavTop productName="Preview" />
        <Main>
            <Unity unityProvider={unityProvider} style={{width: "100vw"}} />
        </Main>
     </Container>
  );
};

const Container = styled.div`
  z-index: 0;
  position: relative;
  height: 100vh;
  width: 100vw;
  min-width: 1000px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  min-height: 750px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
`;
import styled from "styled-components";

export const ButtonAlert = (props) => {
  return (
    <>
      {props.title && <Head>
        <Title theme={props.theme}>{props.title}</Title>
      </Head>}
      <BoxContainer>
        <Button onClick={props.onClick} style={props.style} theme={props.theme}>
          {props.icon && (
            <Icon
              src={props.icon}
              divider={props.iconDivider}
              style={props.iconStyle}
              theme={props.theme}
            />
          )}
          {props.iconDivider && <IconDivider />}
          <div>{props.text}</div>
          <div>{props.secondaryText}</div>
        </Button>
      </BoxContainer>
    </>
  );
};

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 0px;
  background: #fcfcfc;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-shrink: 1;
`;

const Title = styled.h2`
  font-family: "SkyTextRegular";
  font-size: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 8px 1px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: ${(props) => {
    switch (props.theme) {
      case "green":
        return "#41c90a";
      case "red":
        return "#ff6240";
      case "blue":
        return "#0072c9";
      default:
        return "unset";
    }
  }};
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // padding: 5px 18px 5px 18px;
  gap: 1px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  > * {
    margin: 0 auto;
    padding: 5px 18px 5px 18px;
  }
`;

const IconDivider = styled.div`
  border-left: 1px solid #f5f5f5;
  height: 20px;
  margin-left: auto;
`;

const Icon = styled.img`
  height: 14px;
  //   flex: none;
  flex-grow: 0;
  padding-left: 8px;
  padding-right: 10px;
  transition: all 0.125s;
`;

const Button = styled.button`
  font-family: "SkyTextRegular";
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  border: 1px solid #0072c9;
  border-radius: 3px;
  width: max-content;
  min-height: 36px;
  padding: 5px 8px;
  transition: all 0.125s;
  font-size: 12px;
  line-height: 1.6;
  color: "#333333";
  background: ${(props) => (props.theme === "blue" ? "#f5f5f5" : "white")};
  border: 1px solid
    ${(props) => {
      switch (props.theme) {
        case "green":
          return "#41c90a";
        case "red":
          return "#ff6240";
        case "blue":
          return "#f5f5f5";
        default:
          return "unset";
      }
    }};
`;

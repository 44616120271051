import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ViewerPage } from "./pages/ViewerPage";
import { LoaderPage } from "./pages/LoaderPage";
import { TemplatePage } from "./pages/TemplatePage";
import "./constants/fonts.css";
import { LoginPage } from "./pages/LoginPage";
import { UserDetails } from "./utils/auth";
import { ProviderState } from "@microsoft/mgt-element";
import { useEffect } from "react";
import { PreviewPage } from "./pages/PreviewPage";

function App() {
  const [signInStatus, userDetails, accessToken] = UserDetails();

  return (
    <div className="App">
      {signInStatus !== ProviderState.Loading &&
        <Router>
          <Routes>
            <>
              <Route
                exact
                path="/login"
                element={<LoginPage signedIn={signInStatus === ProviderState.SignedIn} />}
              />
              <Route
                exact
                path="/"
                element={<LoaderPage signedIn={signInStatus === ProviderState.SignedIn} userDetails={userDetails}  />}
              />
              <Route
                exact
                path="/viewer"
                element={<ViewerPage signedIn={signInStatus === ProviderState.SignedIn}  />}
              />
              <Route
                exact
                path="/template"
                element={<TemplatePage signedIn={signInStatus === ProviderState.SignedIn}  />}
              />
              <Route
                exact
                path="/preview"
                element={<PreviewPage signedIn={signInStatus === ProviderState.SignedIn} accessToken={accessToken}  />}
              />
            </>
          </Routes>
        </Router>
      }
    </div>
  );
}
export default App;

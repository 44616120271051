import { useEffect } from "react";
import { Providers } from "@microsoft/mgt-react";
import { Camera, Light, Material, Object3D } from "three";

//Counting number of textures
const addTextures = (textures, material) => {
  if (material.map) textures.add(material.map);
  if (material.metalnessMap) textures.add(material.metalnessMap);
  if (material.roughnessMap) textures.add(material.maproughnessMap);
  if (material.lightMap) textures.add(material.lightMap);
  if (material.bumpMap) textures.add(material.bumpMap);
  if (material.normalMap) textures.add(material.normalMap);
  if (material.specularMap) textures.add(material.specularMap);
  if (material.envMap) textures.add(material.envMap);
  if (material.aoMap) textures.add(material.aoMap);
};

const emptyMetadata = {
    trianglesCount: 0,
    verticesCount: 0,
    materialsCount: 0,
    textureCount: 0,
    animationsCount: 0,
    callsCount: 0,
};

export const GenerateMetadata = ({object, setMetadata}) => {
    useEffect(() => {
        var metadata = {...emptyMetadata};

        if (!object) metadata = emptyMetadata;
        else if (object instanceof Object3D) metadata = ObjectMetadata(object);
        else if (object.length > 0) {
            for (var i = 0; i < object.length; i++) {
                if (object[i] instanceof Camera) continue;
                if (object[i] instanceof Light) continue;
                var singleObjectMetadata = ObjectMetadata(object[i]);
                metadata.trianglesCount += singleObjectMetadata.trianglesCount;
                metadata.verticesCount += singleObjectMetadata.verticesCount;
                metadata.materialsCount += singleObjectMetadata.materialsCount;
                metadata.textureCount += singleObjectMetadata.textureCount;
                metadata.animationsCount += singleObjectMetadata.animationsCount;
                metadata.callsCount += singleObjectMetadata.callsCount;
            }
        } else metadata = emptyMetadata;

        setMetadata(metadata);
    }, [object, setMetadata]);
}

const ObjectMetadata = (object) => {
    const materials = new Set();
    const textures = new Set();
    let trianglesCount = 0;
    let verticesCount = 0;
    let callsCount = 0;

    let animationsCount = object.animations ? object.animations.length : 0;

    //Counting number of materials
    object.traverse(function (node) {
        if (node.geometry) {
        if (node.material && !(node.material instanceof Material)) callsCount += node.material.length;
        else callsCount +=1;
        }

        if (node.material) {
        if (node.material instanceof Material) {
            addTextures(textures, node.material);
            materials.add(node.material);
        } else {
            for (var i=0; i<node.material.length; i++) {
            addTextures(textures, node.material[i]);
            materials.add(node.material[i]);
            }
        }
        }
    });

    //Counting number of triangles
    object.traverseVisible(function (node) {
    if (node.isMesh) {
        if (node.geometry) {
            const geometry = node.geometry;
            verticesCount += geometry.attributes.position.count;
            geometry.index !== null
            ? (trianglesCount += geometry.index.count / 3)
            : (trianglesCount += geometry.attributes.position.count / 3);
            //offset value between this function and renderer.info
            trianglesCount += 194;
        }
    }
    });

    const metadata = {
        trianglesCount: trianglesCount,
        verticesCount: verticesCount,
        materialsCount: materials.size,
        textureCount: textures.size,
        animationsCount: animationsCount,
        callsCount: callsCount
    };

    return metadata;
}

import { useEffect, useState } from "react";
import styled from "styled-components";
import NavTop from "../components/ui/organisms/NavTop";
import { Viewer } from "../components/viewer";
import modelStore from "../lib/stores/store";
import { Navigate } from "react-router-dom";
import { Group } from "three";
import { Loader } from "../utils/loader";
import { optimiseModel } from "../utils/modelOptimisation";
import { SceneHierarchy } from "../components/ui/organisms/SceneHierarchy";
import { UploadModal } from "../components/ui/organisms/UploadModal";
import { ModelDetails } from "../components/ui/organisms/ModelDetails";
import { ProgressBar } from "../components/ui/atoms/ProgressBar";

export const ViewerPage = ({signedIn}) => {
  const { model, masterModel, name, setMasterModelStore, setModelStore } = modelStore();
  const [progress, setProgress] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadProgress, setLoadProgress] = useState(0);
  const [exportStatus, setExportStatus] = useState(false);
  const [selection, setSelection] = useState({});

  const [hierarchyWidth, setHierarchyWidth] = useState(240);
  const [dragging, setDragging] = useState(false);

  const [uploadType, setUploadType] = useState(null);
  const [selectedObjects, setSelectedObjects] = useState(null);

  useEffect(() => {
    if (masterModel && !model) {
      if (!(masterModel instanceof Group)) {
        // If only a master model which is not of Object3D type, we still need to download the model
        const loader = new Loader({setScene: setMasterModelStore, setProgress: setLoadProgress});
        loader.loadFileFromURL(masterModel.url, masterModel.name);
      } else {
        // If master model is of Object3D type, we still need to generate a version optimised for ThreeJS
        setModelStore(optimiseModel(masterModel));
      }
    } else if (masterModel && model) {
      // If master + optimised models found, all good to view
      setTimeout(() => {setLoaded(true)}, 3000);
    }
  }, [model, masterModel, setMasterModelStore, setModelStore]);

  if (!signedIn) {
    return <Navigate to={"/login"} />;
  } else if (masterModel === null) {
    return <Navigate to={"/"} />;
  }

  return (
    <Container style={{cursor: dragging ? 'col-resize' : 'unset'}}>
      <NavTop productName={name} />
      <Main
        onMouseMove={(e) => {
          if (dragging) {
            setHierarchyWidth(Math.max(240,e.clientX + 5));
          }
        }}
        onMouseUp={(e) => {
          setDragging(false);
        }}
      >
        {!(model instanceof Group) ? 
        <Loading>
          <ProgressBarContainer>
            <ProgressBar headStyle={{backgroundColor: "#f5f5f5"}} uploadStatus={"Downloading model..."} uploadPercentage={`${loadProgress}%`} />
          </ProgressBarContainer>
          </Loading> :
        <>
        <SceneHierarchy model={model} selected={selection} setSelected={setSelection} hierarchyWidth={hierarchyWidth} setDragging={setDragging} />
        <Viewer model={model} selected={selection} setSelected={setSelection} setProgress={setProgress} exportStatus={exportStatus} hierarchyWidth={hierarchyWidth} />
        <ModelDetails setUploadType={setUploadType} selection={selection} selectedObjects={selectedObjects} setSelectedObjects={setSelectedObjects} />
          {(progress < 100 || !loaded) && <Loading><p>Checking 3D file...</p></Loading>}
        </>
        }
        {uploadType && <UploadModal onClose={() => setUploadType(null)} uploadType={uploadType} setExportStatus={setExportStatus} selectedObjects={selection} />}
      </Main>
    </Container>
  );
};

const Container = styled.div`
  z-index: 0;
  position: relative;
  height: 100vh;
  width: 100vw;
  min-width: 1000px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  min-height: 750px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  // min-width: 1000px;
  height: calc(100vh - 56px);
  min-height: calc(750px - 56px);
`;

const Loading = styled.div`
  z-index: 3;
  position: absolute;
  height: calc(100vh - 56px);
  width: 100vw;
  top: 56px;
  background: #F5F5F5;
  display: flex;
  p {
    margin: auto;
    font-family: 'SkyTextMedium';
    font-size: 16px;
    text-align: center;
    color: #0072C9;
  }
`;

const ProgressBarContainer = styled.div`
  width: 25%;
  background-color: #f5f5f5;
  margin: auto auto auto auto;
`;